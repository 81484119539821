import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { MapPin, Edit, Trash2, Plus, Users } from 'lucide-react';
import { Circles } from 'react-loader-spinner';
import config from '../config';
import '../styles/locations.css';
import EditLocations from './EditLocations';
import AddLocation from './AddLocation';
import UsersLocationModal from './UsersLocationModal';

const LocationsModal = ({ isOpen, onClose, clientId, clientName }) => {
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editingLocation, setEditingLocation] = useState(null);
  const [isAddingLocation, setIsAddingLocation] = useState(false);
  const [managingUsers, setManagingUsers] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const fetchLocations = async () => {
    if (!isOpen) return;
    try {
      setLoading(true);
      const environment = process.env.NODE_ENV || 'development';
      const userId = localStorage.getItem('userId');
      const response = await axios.get(`${config[environment].API_BASE_URL}get_locations.php`, {
        params: { idCliente: clientId, userId: userId }
      });
      const locationsData = Array.isArray(response.data) ? response.data : [];
      setLocations(locationsData);
      setError(null);
    } catch (err) {
      console.error('Error fetching locations:', err);
      setError('Error al cargar las ubicaciones. Por favor, inténtelo de nuevo más tarde.');
      setLocations([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLocations();
  }, [clientId, isOpen]);

  const handleEdit = (location) => {
    setEditingLocation(location);
  };

  const handleDelete = async (locationId) => {
    if (window.confirm('¿Está seguro de que desea eliminar esta ubicación?')) {
      try {
        const environment = process.env.NODE_ENV || 'development';
        const userId = localStorage.getItem('userId');
        await axios.post(`${config[environment].API_BASE_URL}delete_location.php`, {
          locationId,
          userId
        });
        setLocations(locations.filter(loc => loc.id !== locationId));
      } catch (err) {
        console.error('Error deleting location:', err);
        alert('Error al eliminar la ubicación');
      }
    }
  };

  const handleAddLocation = () => {
    setIsAddingLocation(true);
  };

  const handleManageUsers = (location) => {
    setSelectedLocation(location);
    setManagingUsers(true);
  };

  if (!isOpen) return null;

  return (
      <div className="locations-modal-overlay">
        <div className="locations-modal-content">
          <button className="locations-modal-close" onClick={onClose}>&times;</button>
          <div className="locations-modal-header">
            <MapPin size={24} />
            <h2>Ubicaciones del Cliente: {clientName}</h2>
          </div>
          <div className="locations-modal-add-button-container">
            <button className="locations-add-button" onClick={handleAddLocation}>
              <Plus size={18} /> Añadir Ubicación
            </button>
          </div>

          {loading && <div key="loading-container" style={{ width: "100%", height: "40vh", display: "flex", justifyContent: "center", alignContent: "center", flexWrap: "wrap" }}>
            <Circles
                height="80"
                width="80"
                color="#000000"
                ariaLabel="circles-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
            />
          </div>}
          {error && <p className="locations-error">{error}</p>}

          {!loading && !error && locations.length === 0 && (
              <p className="locations-empty">No hay ubicaciones registradas para este cliente.</p>
          )}

          {!loading && !error && locations.length > 0 && (
              <table className="locations-table">
                <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Acciones</th>
                </tr>
                </thead>
                <tbody>
                {locations.map((location) => (
                    <tr key={location.id}>
                      <td>{location.nombre}</td>
                      <td className="actions-cell">
                        <button onClick={() => handleEdit(location)} className="edit-button" title="Editar">
                          <Edit size={18} />
                        </button>
                        <button onClick={() => handleManageUsers(location)} className="users-button" title="Gestionar usuarios">
                          <Users size={18} />
                        </button>
                        <button onClick={() => handleDelete(location.id)} className="delete-button" title="Eliminar">
                          <Trash2 size={18} />
                        </button>
                      </td>
                    </tr>
                ))}
                </tbody>
              </table>
          )}

          {editingLocation && (
              <EditLocations
                  location={editingLocation}
                  onClose={() => setEditingLocation(null)}
                  onSave={(updatedLocation) => {
                    setLocations(locations.map(loc =>
                        loc.id === updatedLocation.id ? updatedLocation : loc
                    ));
                    setEditingLocation(null);
                  }}
              />
          )}

          {isAddingLocation && (
              <AddLocation
                  isOpen={isAddingLocation}
                  onClose={() => setIsAddingLocation(false)}
                  clientId={clientId}
                  clientName={clientName}
                  onLocationAdded={fetchLocations}
              />
          )}

          {managingUsers && selectedLocation && (
              <UsersLocationModal
                  isOpen={managingUsers}
                  onClose={() => {
                    setManagingUsers(false);
                    setSelectedLocation(null);
                  }}
                  locationId={selectedLocation.id}
                  locationName={selectedLocation.nombre}
              />
          )}
        </div>
      </div>
  );
};

export default LocationsModal;