import React, { useState } from 'react';
import '../styles/modal.css';
import '../styles/modalMarcaje.css';
import EditMarcajeForm from './EditMarcajeForm';
import { format } from 'date-fns';

const ModalMarcaje = ({ isOpen, onClose, marcaje, users, clients, onMarcajeUpdate }) => {
  const [isEditing, setIsEditing] = useState(false);

  const validUsers = Array.isArray(users) ? users.filter(user => user && typeof user.idUsuario !== 'undefined') : [];
  const validClients = Array.isArray(clients) ? clients.filter(client => client && typeof client.idCliente !== 'undefined') : [];

  if (!isOpen || !marcaje) return null;

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleEditComplete = (updatedMarcaje) => {
    setIsEditing(false);
    if (onMarcajeUpdate) {
      onMarcajeUpdate(updatedMarcaje);
    }
    onClose();
  };

  const getTipoIncidencia = (idTipoIncidencia) => {
    const tipos = {
      1: 'Jornada Laboral',
      2: 'Pausa Almuerzo',
      3: 'Descanso',
      4: 'Otros'
    };
    return tipos[idTipoIncidencia] || 'Desconocido';
  };

  const getTipoStyle = (idTipoIncidencia) => {
    if (idTipoIncidencia === 2 || idTipoIncidencia === 3) {
      return { color: 'var(--color-error)', fontWeight: 'bold' };
    }
    return {};
  };

  return (
    <div className="modal-overlay-marcaje">
      <div className="modal-content-marcaje">
        <button className="modal-close-marcaje" onClick={onClose}>&times;</button>
        
        {!isEditing ? (
          <>
            <h2>Detalles del Marcaje</h2>
            <div className="modal-info-marcaje">
              <div className="marcaje-info-row">
                <div className="marcaje-info-group">
                  <p><strong>ID Marcaje:</strong> {marcaje.idMarcaje}</p>
                  <p><strong>Operario:</strong> {marcaje.sNombre_Completo}</p>
                  <p><strong>Cliente:</strong> {marcaje.sCliente}</p>
                </div>
                <div className="marcaje-info-group">
                  <p><strong>Fecha:</strong> {
                    (() => {
                      try {
                        let fechaObj;
                        if (marcaje.dFecha instanceof Date) {
                          fechaObj = marcaje.dFecha;
                        } else if (typeof marcaje.dFecha === 'string') {
                          fechaObj = new Date(marcaje.dFecha);
                        } else {
                          fechaObj = new Date(String(marcaje.dFecha));
                        }
                        
                        return format(fechaObj, 'dd/MM/yyyy');
                      } catch (err) {
                        console.error('Error formatting date in modal:', err);
                        return 'Fecha no disponible';
                      }
                    })()
                  }</p>
                  <p><strong>Tipo:</strong> <span style={getTipoStyle(marcaje.idTipoIncidencia)}>{marcaje.sTipoIncidencia || getTipoIncidencia(marcaje.idTipoIncidencia)}</span></p>
                  <p><strong>Estado:</strong> 
                    {marcaje.bModificado ? (
                      <span className="estado-modificado">Modificado</span>
                    ) : (
                      'Original'
                    )}
                  </p>
                </div>
              </div>
              
              <div className="marcaje-time-info">
                <div className="time-box">
                  <div className="time-label">Hora Inicio</div>
                  <div className="time-value">{marcaje.HoraIni}</div>
                </div>
                <div className="time-box">
                  <div className="time-label">Hora Fin</div>
                  <div className="time-value">{marcaje.HoraFin || '-'}</div>
                </div>
                <div className="time-box">
                  <div className="time-label">Duración</div>
                  <div className="time-value">{marcaje.sDuracion || '-'}</div>
                </div>
              </div>
            </div>
            
            <div className="modal-info-marcaje-action">
              <button className="edit-button" onClick={handleEditClick}>Editar Marcaje</button>
            </div>
          </>
        ) : (
          <EditMarcajeForm 
            marcaje={marcaje} 
            onEditComplete={handleEditComplete} 
            users={validUsers}
            clients={validClients}
          />
        )}
      </div>
    </div>
  );
};

export default ModalMarcaje;