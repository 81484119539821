import { format, isValid } from 'date-fns';
import { es } from 'date-fns/locale';

export const isValidTime = (timeStr) => {
  if (!timeStr || timeStr === '-') return false;
  
  const timeRegex = /^([01]?[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/;
  return timeRegex.test(timeStr);
};

export const formatDate = (dateStr) => {
  if (!dateStr) return '-';
  
  const date = new Date(dateStr);
  return isValid(date) ? format(date, 'dd/MM/yyyy', { locale: es }) : '-';
};

export const formatTime = (timeStr) => {
  if (!isValidTime(timeStr)) return '-';
  return timeStr;
};

export const parseDuration = (durationStr) => {
  if (!isValidTime(durationStr)) return 0;
  
  const [hours, minutes, seconds] = durationStr.split(':').map(Number);
  return (hours * 3600) + (minutes * 60) + seconds;
};

export const formatDuration = (totalSeconds) => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
};

export const calculateDuration = (startTime, endTime) => {
  if (!isValidTime(startTime) || !isValidTime(endTime)) return '00:00:00';

  const [startHours, startMinutes, startSeconds] = startTime.split(':').map(Number);
  const [endHours, endMinutes, endSeconds] = endTime.split(':').map(Number);

  let diffSeconds = endSeconds - startSeconds;
  let diffMinutes = endMinutes - startMinutes;
  let diffHours = endHours - startHours;

  if (diffSeconds < 0) {
    diffSeconds += 60;
    diffMinutes--;
  }
  if (diffMinutes < 0) {
    diffMinutes += 60;
    diffHours--;
  }
  if (diffHours < 0) {
    diffHours += 24;
  }

  return `${String(diffHours).padStart(2, '0')}:${String(diffMinutes).padStart(2, '0')}:${String(diffSeconds).padStart(2, '0')}`;
};

export const validateWorkLog = (log) => {
  return {
    ...log,
    dFecha: log.dFecha || null,
    HoraIni: formatTime(log.HoraIni),
    HoraFin: formatTime(log.HoraFin),
    sDuracion: isValidTime(log.sDuracion) ? log.sDuracion : '00:00:00',
    sCliente: log.sCliente || '-'
  };
};

export const calculateTotalDuration = (logs) => {
  let totalSeconds = 0;

  logs.forEach(log => {
    if (isValidTime(log.sDuracion)) {
      const [hours, minutes, seconds] = log.sDuracion.split(':').map(Number);
      totalSeconds += (hours * 3600) + (minutes * 60) + seconds;
    }
  });

  return formatDuration(totalSeconds);
};