import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import config from '../config';
import "../styles/clients.css";
import SearchComponent from './SearchComponent';
import Modal from './ModalClient';
import { useAuth } from '../AuthContext';
import { Circles } from 'react-loader-spinner';
import Swal from 'sweetalert2';

const UsersClients = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filteredData, setFilteredData] = useState([]);
    const [error, setError] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedClient, setSelectedClient] = useState(null);
    const [showDeleted, setShowDeleted] = useState(false);

    const { isAuthenticated, logout, getAuthToken } = useAuth();

    const environment = process.env.NODE_ENV || 'development';
    const apiUrl = `${config[environment].PHP_GET_CLIENTS_URL}`;
    const updateEstadoUrl = `${config[environment].PHP_UPDATE_ESTADO_CLIENTE_URL}`;
    const deleteClientUrl = `${config[environment].PHP_DELETE_CLIENT_URL}`;
    const restoreClientUrl = `${config[environment].PHP_RESTORE_CLIENT_URL}`;

    const fetchClients = useCallback(async () => {
        if (!isAuthenticated) return;

        try {
            const token = getAuthToken();
            const userId = localStorage.getItem('userId');
            const response = await axios.get(`${apiUrl}?userId=${userId}`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setData(response.data);
            setFilteredData(response.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            if (error.response && error.response.status === 401) {
                setError('Fallo de autenticación. Por favor, inicie sesión de nuevo.');
                logout();
            } else {
                setError('No hay respuesta por parte del servidor, compruebe su conexión e inténtelo de nuevo.');
            }
            setLoading(false);
        }
    }, [isAuthenticated, logout, getAuthToken, apiUrl]);

    useEffect(() => {
        fetchClients();
    }, [fetchClients]);

    const handleSearch = (searchTerm) => {
        const filtered = data.filter((client) =>
            Object.values(client).some((value) =>
                value.toString().toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
        setFilteredData(filtered);
    };

    const handleRowClick = (client) => {
        setSelectedClient(client);
        setIsModalOpen(true);
    };

    const handleClientUpdate = (updatedClient) => {
        setData(prevData => prevData.map(client =>
            client.idCliente === updatedClient.idCliente ? updatedClient : client
        ));
        setFilteredData(prevData => prevData.map(client =>
            client.idCliente === updatedClient.idCliente ? updatedClient : client
        ));
        setIsModalOpen(false);
    };

    const handleKeyDown = (event, client) => {
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            handleRowClick(client);
        }
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedClient(null);
    };

    const handleEstadoChange = async (client, newEstado) => {
        try {
            const token = getAuthToken();
            const userId = localStorage.getItem('userId');

            const updatedClient = {
                ...client,
                status: newEstado ? 'Activo' : 'Inactivo'
            };

            setData(prevData => prevData.map(c => 
                c.idCliente === client.idCliente ? updatedClient : c
            ));
            setFilteredData(prevData => prevData.map(c => 
                c.idCliente === client.idCliente ? updatedClient : c
            ));

            const updateResponse = await axios.post(updateEstadoUrl, {
                idCliente: client.idCliente,
                bEstado: newEstado ? 1 : 0,
                userId: userId
            }, {
                headers: { 'Authorization': `Bearer ${token}` }
            });

            if (!updateResponse.data.success) {
                setData(prevData => prevData.map(c => 
                    c.idCliente === client.idCliente ? client : c
                ));
                setFilteredData(prevData => prevData.map(c => 
                    c.idCliente === client.idCliente ? client : c
                ));
                throw new Error(updateResponse.data.message || 'Error al actualizar el estado');
            }

        } catch (error) {
            console.error('Error al actualizar el estado:', error);
            if (error.response && error.response.status === 401) {
                setError('Fallo de autenticación. Por favor, inicie sesión de nuevo.');
                logout();
            } else {
                setError(`Error al actualizar el estado: ${error.message}`);
            }
        }
    };

    const handleDeleteClick = useCallback(async (clientId, isDelete) => {
        const result = await Swal.fire({
            title: '¿Estás seguro?',
            text: `¿Deseas ${isDelete ? 'eliminar' : 'restaurar'} este cliente?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: isDelete ? 'Sí, eliminar' : 'Sí, restaurar',
            cancelButtonText: 'Cancelar'
        });

        if (result.isConfirmed) {
            try {
                const token = getAuthToken();
                const userId = localStorage.getItem('userId');
                const response = await axios.post(deleteClientUrl, {
                    idCliente: clientId,
                    bBorrado: isDelete ? 1 : 0,
                    userId: userId
                }, {
                    headers: { 'Authorization': `Bearer ${token}` }
                });
    
                if (response.data.success) {
                    await fetchClients();
                    closeModal();
                    await Swal.fire({
                        title: '¡Completado!',
                        text: `Cliente ${isDelete ? 'eliminado' : 'restaurado'} correctamente`,
                        icon: 'success',
                        timer: 2000,
                        showConfirmButton: false
                    });
                } else {
                    await Swal.fire({
                        title: 'Error',
                        text: response.data.message || `Error al ${isDelete ? 'eliminar' : 'restaurar'} el cliente`,
                        icon: 'error'
                    });
                    setError(response.data.message || `Error al ${isDelete ? 'eliminar' : 'restaurar'} el cliente`);
                }
            } catch (error) {
                console.error(`Error al ${isDelete ? 'eliminar' : 'restaurar'} el cliente:`, error);
                await Swal.fire({
                    title: 'Error',
                    text: `Error al ${isDelete ? 'eliminar' : 'restaurar'} el cliente: ${error.message}`,
                    icon: 'error'
                });
                setError(`Error al ${isDelete ? 'eliminar' : 'restaurar'} el cliente: ${error.message}`);
            }
        }
    }, [fetchClients, setError, getAuthToken, deleteClientUrl]);

    const handleRestoreClick = useCallback(async (clientId) => {
        try {
            const token = getAuthToken();
            const userId = localStorage.getItem('userId');
            const response = await axios.post(restoreClientUrl, {
                idCliente: clientId,
                userId: userId
            }, {
                headers: { 'Authorization': `Bearer ${token}` }
            });

            if (response.data.success) {
                await fetchClients();
                closeModal();
            } else {
                setError(response.data.message || 'Error al restaurar el cliente');
            }
        } catch (error) {
            console.error('Error al restaurar el cliente:', error);
            setError(`Error al restaurar el cliente: ${error.message}`);
        }
    }, [fetchClients, setError, getAuthToken, restoreClientUrl]);

    const conditionallyShowDeleteButton = useCallback((client) => {
        if (client.bBorrado) {
            return (
                <button className="restore-button" onClick={() => handleRestoreClick(client.idCliente)}>
                    Restaurar
                </button>
            );
        } else {
            return (
                <button className="delete-button" onClick={() => handleDeleteClick(client.idCliente, true)}>
                    Eliminar
                </button>
            );
        }
    }, [handleDeleteClick, handleRestoreClick]);

    const toggleShowDeleted = () => {
        setShowDeleted(!showDeleted);
    };

    if (!isAuthenticated) return <div>Por favor, inicie sesión para ver esta página.</div>;
    if (loading) return (
        <div style={{ width: "100%", height: "90vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Circles height="80" width="80" color="#000000" ariaLabel="circles-loading" />
        </div>
    );
    if (error) return <div>{error}</div>;
    if (data.length === 0) return <div>No hay clientes disponibles</div>;

    const visibleClients = showDeleted ? filteredData : filteredData.filter(client => !client.bBorrado);

    return (
        <div className='main-clients-fetch'>
            <h1 className='name-left'>Clientes</h1>
            <div className='clients-controls'>
                <SearchComponent onSearch={handleSearch} />
                <label className='show-deleted-checkbox'>
                    <input
                        type="checkbox"
                        checked={showDeleted}
                        onChange={toggleShowDeleted}
                    />
                    Mostrar clientes eliminados
                </label>
            </div>
            <div className='table-clients'>
                <div className='tr-clients header'>
                    <div className='td-clients-item'>Cliente</div>
                    <div className='td-clients-item'>Persona de contacto</div>
                    <div className='td-clients-item'>Dirección</div>
                    <div className='td-clients-item'>Provincia</div>
                    <div className='td-clients-item'>Estado</div>
                </div>
                <div className='tbody-clients'>
                    {visibleClients.map((client) => (
                        <div
                            key={client.idCliente}
                            className={`tr-clients ${client.bBorrado ? 'deleted-client' : ''}`}
                            role="button"
                            tabIndex={0}
                            onKeyDown={(event) => handleKeyDown(event, client)}
                        >
                            <div className='td-clients-item' onClick={() => handleRowClick(client)}>{client.sCliente}</div>
                            <div className='td-clients-item' onClick={() => handleRowClick(client)}>{client.sContacto}</div>
                            <div className='td-clients-item' onClick={() => handleRowClick(client)}>{`${client.sTipoVia} ${client.sDireccion}`}</div>
                            <div className='td-clients-item' onClick={() => handleRowClick(client)}>{client.sProvincia}</div>
                            <div className='td-clients-item'>
                                <input
                                    type="checkbox"
                                    checked={client.status === 'Activo'}
                                    onChange={(e) => handleEstadoChange(client, e.target.checked)}
                                    onClick={(e) => e.stopPropagation()}
                                    disabled={client.bBorrado}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {isModalOpen &&
                <Modal
                    isOpen={isModalOpen}
                    onClose={closeModal}
                    client={selectedClient}
                    onClientUpdate={handleClientUpdate}
                    onDeleteClick={handleDeleteClick}
                    conditionallyShowDeleteButton={conditionallyShowDeleteButton}
                />
            }
        </div>
    );
};

export default UsersClients;