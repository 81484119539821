import React from 'react';
import DatePicker from 'react-datepicker';
import SearchComponent from './SearchComponent';
import 'react-datepicker/dist/react-datepicker.css';
import '../styles/filters.css';

const FilterTable = ({
  handleSearch,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  ignoreDateFilter,
  selectedUser,
  handleUserFilter,
  users,
  selectedClient,
  handleClientFilter,
  clients,
  rowLimit,
  handleRowLimitChange
}) => {
  const validUsers = Array.isArray(users) ? users : [];
  const validClients = Array.isArray(clients) ? clients : [];

  return (
    <table className="filter-table">
      <thead className="filter-table__head">
        <tr className="filter-table__row filter-table__row--header">
          <th className="filter-table__header">Búsqueda</th>
          <th className="filter-table__header">Fecha Inicio</th>
          <th className="filter-table__header">Fecha Fin</th>
          <th className="filter-table__header">Usuario</th>
          <th className="filter-table__header">Cliente</th>
          <th className="filter-table__header">Límite de filas</th>
        </tr>
      </thead>
      <tbody className="filter-table__body">
        <tr className="filter-table__row">
          <td className="filter-table__cell filter-table__cell--search">
            <SearchComponent onSearch={handleSearch} placeholder="Buscar..." className="filter-table__search" />
          </td>
          <td className="filter-table__cell filter-table__cell--date">
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              dateFormat="dd-MM-yyyy"
              disabled={ignoreDateFilter}
              className="filter-table__datepicker"
            />
          </td>
          <td className="filter-table__cell filter-table__cell--date">
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              dateFormat="dd-MM-yyyy"
              disabled={ignoreDateFilter}
              className="filter-table__datepicker"
            />
          </td>
          <td className="filter-table__cell filter-table__cell--select">
            <select 
              value={selectedUser} 
              onChange={handleUserFilter}
              className="filter-table__select filter-table__select--user"
            >
              <option value="">Todos los usuarios</option>
              {validUsers.map((user) => (
                <option 
                  key={user.id} 
                  value={user.id} 
                  className="filter-table__option"
                >
                  {user.sNombreCompleto}
                </option>
              ))}
            </select>
          </td>
          <td className="filter-table__cell filter-table__cell--select">
            <select 
              value={selectedClient} 
              onChange={handleClientFilter}
              className="filter-table__select filter-table__select--client"
            >
              <option value="">Todos los clientes</option>
              {validClients.map((client) => (
                <option 
                  key={client.idCliente} 
                  value={client.idCliente} 
                  className="filter-table__option"
                >
                  {client.sCliente}
                </option>
              ))}
            </select>
          </td>
          <td className="filter-table__cell filter-table__cell--select">
            <select 
              value={rowLimit} 
              onChange={handleRowLimitChange}
              className="filter-table__select filter-table__select--limit"
            >
              <option value={25} className="filter-table__option">25 filas</option>
              <option value={50} className="filter-table__option">50 filas</option>
              <option value={100} className="filter-table__option">100 filas</option>
            </select>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default FilterTable;