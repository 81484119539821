import React from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import Home from './sites/Home';
import Saas from './sites/smalSaas';
import Login from './sites/Login';
import RegisterStaff from './sites/RegisterStaff';
import RegisterClient from './sites/RegisterClient';
import WorkdayLog from './sites/WorkdayLog';
import MaterialRequests from './sites/MaterialReq';
import TermsService from './components/TermsService';
import PrivacyPolicy from './components/PrivacyPolicy';
import Unauthorized from './sites/Unauthorized';
import Admin from './sites/Admin';
import Clients from './sites/Clients';
import Staff from './sites/Staff';
import Suppliers from './sites/Suppliers';
import WorkLog from './sites/WorkLog';
import Supplies from './sites/Supplies';
import EditPermissions from './components/ModalPermissions';
import CreateSchedule from './sites/CreateSchedule';
import RequestsList from './components/RequestsList';
import ModuleUnlicensed from './sites/ModuleUnlicensed';
import CalendarioPage from "./components/Schedule";

const MODULE_ROUTE_MAP = {
    // Módulo de Gestión
    'MANAGEMENT': [
        '/Saas/admin',
        '/Saas/admin/registerstaff',
        '/Saas/admin/registerclient',
        '/Saas/admin/management/clients',
        '/Saas/admin/management/operarios',
        '/Saas/admin/editpermissions'
    ],

    // Módulo de Control de Presencia
    'PRESENCE': [
        '/Saas/admin/presencecontrol/worklog',
        '/Saas/admin/presencecontrol/schedules',
        '/Saas/workdaylog'
    ],

    // Módulo de Materiales
    'MATERIALS': [
        '/Saas/admin/management/proveedores',
        '/Saas/admin/materials/supplies',
        '/Saas/admin/materials/requests',
        '/Saas/materialrequests'
    ],
    'NOTIFICATIONS': [
        '/Saas/admin/schedule'
    ]
};

const getModuleForRoute = (route) => {
    const allRoutes = [];
    for (const [module, routes] of Object.entries(MODULE_ROUTE_MAP)) {
        routes.forEach(r => allRoutes.push({ module, route: r }));
    }

    allRoutes.sort((a, b) => b.route.length - a.route.length);

    for (const item of allRoutes) {
        if (route.startsWith(item.route)) {
            return item.module;
        }
    }
    return null;
}

const ProtectedRoute = ({ element: Component, ...rest }) => {
    const { isAuthenticated, checkRoutePermission, checkModuleLicense, loadingLicenses } = useAuth();
    const location = useLocation();

    if (loadingLicenses) {
        return <div className="loading-spinner">Cargando...</div>;
    }

    const renderComponent = () => {
        if (!isAuthenticated) {
            return <Navigate to="/login" state={{ from: location }} replace />;
        }

        if (!checkRoutePermission(location.pathname)) {
            return <Navigate to="/unauthorized" replace />;
        }

        const requiredModule = getModuleForRoute(location.pathname);
        if (requiredModule && !checkModuleLicense(requiredModule)) {
            return <Navigate to="/module-unlicensed" state={{ moduleName: requiredModule }} replace />;
        }

        return <Component {...rest} />;
    };

    return renderComponent();
};

const AppRoutes = () => {
    return (
        <Routes>
            {/* Páginas de inicio */}
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/terms" element={<TermsService />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />

            {/* Páginas del Trabajador */}
            <Route path="/Saas" element={<ProtectedRoute element={Saas} />} />
            <Route path="/Saas/workdaylog" element={<ProtectedRoute element={WorkdayLog} />} />
            <Route path="/Saas/materialrequests" element={<ProtectedRoute element={MaterialRequests} />} />

            {/* Páginas del Administrador */}
            <Route path="/Saas/admin" element={<ProtectedRoute element={Admin} />} />
            <Route path="/Saas/admin/registerstaff" element={<ProtectedRoute element={RegisterStaff} />} />
            <Route path="/Saas/admin/registerclient" element={<ProtectedRoute element={RegisterClient} />} />
            <Route path="/Saas/admin/management/clients" element={<ProtectedRoute element={Clients} />} />
            <Route path="/Saas/admin/management/operarios" element={<ProtectedRoute element={Staff} />} />
            <Route path="/Saas/admin/management/proveedores" element={<ProtectedRoute element={Suppliers} />} />
            <Route path="/Saas/admin/presencecontrol/worklog" element={<ProtectedRoute element={WorkLog} />} />
            <Route path="/Saas/admin/materials/supplies" element={<ProtectedRoute element={Supplies} />} />
            <Route path="/Saas/admin/editpermissions" element={<ProtectedRoute element={EditPermissions} />} />
            <Route path="/Saas/admin/presencecontrol/schedules" element={<ProtectedRoute element={CreateSchedule} />} />
            <Route path="/Saas/admin/materials/requests" element={<ProtectedRoute element={RequestsList} />} />
            <Route path="/Saas/admin/presencecontrol/schedule" element={<ProtectedRoute element={CalendarioPage} />} />

            {/* Página de Unauthorized */}
            <Route path="/unauthorized" element={<Unauthorized />} />
            {/* Página de módulos sin licencia */}
            <Route path="/module-unlicensed" element={<ModuleUnlicensed />} />
        </Routes>
    );
};

export default AppRoutes;