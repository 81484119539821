import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { X, Plus, Trash2, Users, UserPlus, User } from 'lucide-react';
import config from '../config';
import '../styles/modal.css';

const ModalGroups = ({ userId, isOpen, onClose, onGroupsChange }) => {
    const [grupos, setGrupos] = useState([]);
    const [usuarios, setUsuarios] = useState([]);
    const [grupoSeleccionado, setGrupoSeleccionado] = useState(null);
    const [usuariosDelGrupo, setUsuariosDelGrupo] = useState([]);
    const [usuarioSeleccionado, setUsuarioSeleccionado] = useState('');
    const [loading, setLoading] = useState(false);
    const [LoadingUsuarios, setLoadingUsuarios] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    const environment = process.env.NODE_ENV || 'development';
    const apiBaseUrl = config[environment].API_BASE_URL;

    useEffect(() => {
        if (!isOpen) return;

        const cargarGrupos = async () => {
            const userId = localStorage.getItem('userId');
            setLoading(true);
            try {
                const response = await axios.get(
                    `${config[environment].PHP_GET_GROUPS_URL}?userId=${userId}`
                );
                setGrupos(response.data);
                setLoading(false);
            } catch (err) {
                console.error('Error al cargar grupos:', err);
                setError('Error al cargar los grupos');
                setLoading(false);
            }
        };

        cargarGrupos();
    }, [apiBaseUrl, environment, userId, isOpen]);

    useEffect(() => {
        if (!isOpen) return;

        const cargarUsuarios = async () => {
            const userId = localStorage.getItem('userId');
            setLoadingUsuarios(true);
            try {
                const response = await axios.get(
                    `${config[environment].PHP_GET_USERS_URL}?userId=${userId}`
                );
                setUsuarios(response.data);
                setLoadingUsuarios(false);
            } catch (err) {
                console.error('Error al cargar usuarios:', err);
                setError('Error al cargar los usuarios');
                setLoadingUsuarios(false);
            }
        };

        cargarUsuarios();
    }, [apiBaseUrl, environment, userId, isOpen]);

    const cargarUsuariosDelGrupo = async (grupoId) => {
        if (!grupoId) return;

        setLoading(true);
        try {
            const userId = localStorage.getItem('userId');
            const response = await axios.get(
                `${apiBaseUrl}get_group_users.php?userId=${userId}&grupoId=${grupoId}`
            );
            setUsuariosDelGrupo(response.data);
            setLoading(false);
        } catch (err) {
            console.error('Error al cargar usuarios del grupo:', err);
            setError('Error al cargar los usuarios del grupo');
            setLoading(false);
        }
    };

    const handleSeleccionarGrupo = (grupoId) => {
        setGrupoSeleccionado(grupoId);
        cargarUsuariosDelGrupo(grupoId);
        setError(null);
        setSuccess(null);
    };

    const handleCrearGrupo = async () => {
        setLoading(true);
        try {
            const userId = localStorage.getItem('userId');
            const formData = new FormData();
            formData.append('userId', userId);
            const response = await axios.post(`${apiBaseUrl}create_group.php`, formData);

            setGrupos([...grupos, response.data]);
            setGrupoSeleccionado(response.data.id);
            setUsuariosDelGrupo([]);
            setSuccess('Grupo creado correctamente');
            setError(null);

            if (onGroupsChange) onGroupsChange();

            setLoading(false);
        } catch (err) {
            console.error('Error al crear grupo:', err);
            setError('Error al crear el grupo');
            setLoading(false);
        }
    };

    const handleEliminarGrupo = async () => {
        if (!grupoSeleccionado) return;

        if (!window.confirm('¿Está seguro de que desea eliminar este grupo?')) {
            return;
        }

        setLoading(true);
        try {
            const userId = localStorage.getItem('userId');
            await axios.delete(`${apiBaseUrl}delete_group.php`, {
                data: {
                    userId: userId,
                    grupoId: grupoSeleccionado
                }
            });

            setGrupos(grupos.filter(grupo => grupo.id !== grupoSeleccionado));
            setGrupoSeleccionado(null);
            setUsuariosDelGrupo([]);
            setSuccess('Grupo eliminado correctamente');
            setError(null);

            if (onGroupsChange) onGroupsChange();

            setLoading(false);
        } catch (err) {
            console.error('Error al eliminar grupo:', err);
            setError('Error al eliminar el grupo');
            setLoading(false);
        }
    };

    const handleAddUsuario = async () => {
        if (!grupoSeleccionado || !usuarioSeleccionado) return;

        if (usuariosDelGrupo.some(u => u.id === usuarioSeleccionado)) {
            setError('Este usuario ya está en el grupo');
            return;
        }

        // Buscar el usuario sin convertir a entero
        const usuarioAgregado = usuarios.find(u => u.id === usuarioSeleccionado);

        // Verificar que el usuario existe
        if (!usuarioAgregado) {
            console.error('Usuario no encontrado en la lista', {
                usuarioSeleccionadoId: usuarioSeleccionado,
                usuarios: usuarios
            });
            setError('Error: Usuario no encontrado en la lista');
            return;
        }

        setLoading(true);
        try {
            await axios.post(`${apiBaseUrl}add_user_to_group.php`, {
                userId: usuarioAgregado,
                grupoId: grupoSeleccionado,
                idUsuarioAdd: usuarioSeleccionado
            });

            setUsuariosDelGrupo([...usuariosDelGrupo, usuarioAgregado]);
            setUsuarioSeleccionado('');
            setSuccess('Usuario añadido al grupo correctamente');
            setError(null);

            if (onGroupsChange) onGroupsChange();

            setLoading(false);
        } catch (err) {
            console.error('Error al añadir usuario al grupo:', err);
            setError('Error al añadir el usuario al grupo');
            setLoading(false);
        }
    };

    const handleEliminarUsuario = async (idUsuario) => {
        if (!grupoSeleccionado) return;

        setLoading(true);
        try {
            const userId = localStorage.getItem('userId');
            await axios.delete(`${apiBaseUrl}remove_user_from_group.php`, {
                data: {
                    userId: userId,
                    grupoId: grupoSeleccionado,
                    idUsuarioRemove: idUsuario
                }
            });

            setUsuariosDelGrupo(usuariosDelGrupo.filter(u => u.id !== idUsuario));
            setSuccess('Usuario eliminado del grupo correctamente');
            setError(null);

            if (onGroupsChange) onGroupsChange();

            setLoading(false);
        } catch (err) {
            console.error('Error al eliminar usuario del grupo:', err);
            setError('Error al eliminar el usuario del grupo');
            setLoading(false);
        }
    };

    if (!isOpen) return null;

    return (
        <div className="modal-group-overlay">
            <div className="modal-group-container">
                <div className="modal-group-header">
                    <h2><Users size={20} /> Administrar Grupos</h2>
                    <button className="close-group-button" onClick={onClose}>
                        <X size={20} />
                    </button>
                </div>

                {loading ? (
                    <div className="modal-group-loading">Cargando...</div>
                ) : (
                    <div className="modal-group-content">
                        {error && <div className="modal-group-error">{error}</div>}
                        {success && <div className="modal-group-success">{success}</div>}

                        <div className="grupos-layout">
                            <div className="grupos-lista">
                                <div className="grupos-header">
                                    <h3>Grupos</h3>
                                    <button className="btn-new-group" onClick={handleCrearGrupo}>
                                        <Plus size={16} /> Nuevo grupo
                                    </button>
                                </div>

                                {grupos.length === 0 ? (
                                    <div className="no-grupos">
                                        <p>No hay grupos creados.</p>
                                    </div>
                                ) : (
                                    <ul className="grupos-items">
                                        {grupos.map((grupo) => (
                                            <li
                                                key={grupo.id}
                                                className={`grupo-item ${grupoSeleccionado === grupo.id ? 'active' : ''}`}
                                                onClick={() => handleSeleccionarGrupo(grupo.id)}
                                            >
                                                <div className="grupo-info">
                                                    <span className="grupo-title">Grupo {grupo.id}</span>
                                                    <span className="grupo-users-count">{grupo.numUsuarios} operarios</span>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>

                            <div className="grupo-detalle">
                                {grupoSeleccionado ? (
                                    <>
                                        <div className="grupo-detalle-header">
                                            <h3>Grupo {grupoSeleccionado}</h3>
                                            <button className="btn-delete-group" onClick={handleEliminarGrupo}>
                                                <Trash2 size={16} /> Eliminar grupo
                                            </button>
                                        </div>

                                        <div className="add-user-form">
                                            <h4><UserPlus size={16} /> Añadir operario al grupo</h4>
                                            <div className="add-user-inputs">
                                                <select
                                                    value={usuarioSeleccionado}
                                                    onChange={(e) => setUsuarioSeleccionado(e.target.value)}
                                                    className="user-select"
                                                >
                                                    <option value="">Seleccionar operario</option>
                                                    {usuarios.map((usuario) => (
                                                        <option key={usuario.id} value={usuario.id}>
                                                            {usuario.sNombreCompleto}
                                                        </option>
                                                    ))}
                                                </select>
                                                <button
                                                    className="btn-add-user"
                                                    onClick={handleAddUsuario}
                                                    disabled={!usuarioSeleccionado}
                                                >
                                                    <Plus size={16} /> Añadir
                                                </button>
                                            </div>
                                        </div>

                                        <div className="users-list-container">
                                            <h4><User size={16} /> Operarios en este grupo</h4>
                                            {usuariosDelGrupo.length === 0 ? (
                                                <div className="no-usuarios">
                                                    <p>No hay operarios en este grupo.</p>
                                                </div>
                                            ) : (
                                                <ul className="users-list">
                                                    {usuariosDelGrupo.map((usuario) => (
                                                        <li key={usuario.id} className="user-item">
                                                            <span>{usuario.sNombreCompleto}</span>
                                                            <button
                                                                className="btn-remove-user"
                                                                onClick={() => handleEliminarUsuario(usuario.id)}
                                                            >
                                                                <Trash2 size={16} />
                                                            </button>
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                        </div>
                                    </>
                                ) : (
                                    <div className="no-grupo-selected">
                                        <p>Seleccione un grupo para ver sus detalles o cree uno nuevo.</p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}

                <div className="modal-footer">
                    <button className="btn-close" onClick={onClose}>
                        Cerrar
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ModalGroups;