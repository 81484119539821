import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../config';
import { X, Check } from 'lucide-react';
import '../styles/modalrequests.css';

const ModalRequest = ({ paquete, onClose, onUpdate, onStateChange }) => {
    const [estados, setEstados] = useState([]);
    const [selectedMovimientos, setSelectedMovimientos] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [localPaquete, setLocalPaquete] = useState(paquete);

    const environment = process.env.NODE_ENV || 'development';

    useEffect(() => {
        const normalizedPaquete = {
            ...paquete,
            movimientos: paquete.movimientos.map(mov => ({
                ...mov,
                idEstado: String(mov.idEstado)
            }))
        };

        setLocalPaquete(normalizedPaquete);
    }, [paquete]);

    useEffect(() => {
        const fetchEstados = async () => {
            try {
                const userId = localStorage.getItem('userId');
                const response = await axios.get(
                    `${config[environment].API_BASE_URL}get_estados.php`,
                    { params: { userId } }
                );

                const normalizedEstados = response.data.map(estado => ({
                    ...estado,
                    idEstado: String(estado.idEstado)
                }));

                setEstados(normalizedEstados);
            } catch (err) {
                setError('Error al cargar los estados disponibles');
            }
        };

        fetchEstados();
    }, [environment]);

    const handleCheckboxChange = (idMovimiento) => {
        setSelectedMovimientos(prev =>
            prev.includes(idMovimiento)
                ? prev.filter(id => id !== idMovimiento)
                : [...prev, idMovimiento]
        );
    };

    const handleSelectAll = () => {
        setSelectedMovimientos(prev =>
            prev.length === localPaquete.movimientos.length
                ? []
                : localPaquete.movimientos.map(m => m.idMovimiento)
        );
    };

    const getEstadoText = (idEstado) => {
        const estado = estados.find(e => String(e.idEstado) === String(idEstado));
        return estado?.sEstado || 'Cargando...';
    };

    const updateEstado = async (idMovimiento, idEstado) => {
        try {
            setLoading(true);
            const userId = localStorage.getItem('userId');
            if (!userId) {
                throw new Error('No se encontró el ID de usuario');
            }

            const updatedMovimientos = localPaquete.movimientos.map(mov =>
                mov.idMovimiento === idMovimiento
                    ? { ...mov, idEstado: String(idEstado) }
                    : mov
            );

            const updatedPaquete = {
                ...localPaquete,
                movimientos: updatedMovimientos
            };

            setLocalPaquete(updatedPaquete);
            onUpdate(updatedPaquete);

            await axios.post(`${config[environment].API_BASE_URL}update_movimiento_estado.php`, {
                userId,
                idMovimiento,
                idEstado
            });

            try {
                await axios.post(`${config[environment].API_BASE_URL}update_packages.php`, {
                    userId,
                    idPaquete: localPaquete.idPaquete
                });
            } catch (updateError) {
                console.error('Error al actualizar el estado del paquete:', updateError);
            }

            if (onStateChange) {
                onStateChange();
            }

            setError(null);
        } catch (err) {
            setError('Error al actualizar el estado: ' + (err.message || 'Error desconocido'));
            setLocalPaquete(paquete);
            onUpdate(paquete);
        } finally {
            setLoading(false);
        }
    };

    const updateSelectedEstados = async (idEstado) => {
        if (selectedMovimientos.length === 0) return;

        try {
            setLoading(true);
            const userId = localStorage.getItem('userId');
            if (!userId) {
                throw new Error('No se encontró el ID de usuario');
            }

            const updatedMovimientos = localPaquete.movimientos.map(mov =>
                selectedMovimientos.includes(mov.idMovimiento)
                    ? { ...mov, idEstado: String(idEstado) }
                    : mov
            );

            const updatedPaquete = {
                ...localPaquete,
                movimientos: updatedMovimientos
            };

            setLocalPaquete(updatedPaquete);
            onUpdate(updatedPaquete);

            for (const idMovimiento of selectedMovimientos) {
                await axios.post(`${config[environment].API_BASE_URL}update_movimiento_estado.php`, {
                    userId,
                    idMovimiento,
                    idEstado
                });
            }

            try {
                await axios.post(`${config[environment].API_BASE_URL}update_packages.php`, {
                    userId,
                    idPaquete: localPaquete.idPaquete
                });
            } catch (updateError) {
                console.error('Error al actualizar el estado del paquete:', updateError);
            }

            if (onStateChange) {
                onStateChange();
            }

            setError(null);
            setSelectedMovimientos([]);
        } catch (err) {
            setError('Error al actualizar los estados seleccionados: ' + (err.message || 'Error desconocido'));
            setLocalPaquete(paquete);
            onUpdate(paquete);
        } finally {
            setLoading(false);
        }
    };

    const CustomCheckbox = ({ checked, onChange }) => (
        <div className="checkbox-wrapper" onClick={onChange}>
            <div className={`custom-checkbox ${checked ? 'checked' : ''}`}>
                {checked && <Check size={14} color="white" />}
            </div>
        </div>
    );

    return (
        <div className="modal-requests-overlay">
            <div className="modal-requests-container">
                <div className="modal-requests-header">
                    <h2 className="modal-requests-title">Detalles del Paquete #{localPaquete.idPaquete}</h2>
                    <button onClick={onClose} className="modal-requests-close">
                        <X size={24} />
                    </button>
                </div>

                <div className="modal-requests-content">
                    {error && <div className="error-message">{error}</div>}

                    {selectedMovimientos.length > 0 && (
                        <div className="bulk-actions">
                            <span>{selectedMovimientos.length} movimientos seleccionados</span>
                            <div className="bulk-action-controls">
                                <select
                                    className="estado-select"
                                    onChange={(e) => updateSelectedEstados(e.target.value)}
                                    disabled={loading}
                                    defaultValue=""
                                >
                                    <option value="" disabled>Cambiar estado a...</option>
                                    {estados.map(estado => (
                                        <option key={estado.idEstado} value={estado.idEstado}>
                                            {estado.sEstado}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    )}

                    <table className="movements-table">
                        <thead>
                        <tr>
                            <th style={{width: '40px'}}>
                                <CustomCheckbox
                                    checked={selectedMovimientos.length === localPaquete.movimientos.length && localPaquete.movimientos.length > 0}
                                    onChange={handleSelectAll}
                                />
                            </th>
                            <th>ID</th>
                            <th>Artículo</th>
                            <th>Unidades</th>
                            <th>Estado Actual</th>
                            <th>Cambiar Estado</th>
                        </tr>
                        </thead>
                        <tbody>
                        {localPaquete.movimientos.map((movimiento) => (
                            <tr key={movimiento.idMovimiento}>
                                <td>
                                    <CustomCheckbox
                                        checked={selectedMovimientos.includes(movimiento.idMovimiento)}
                                        onChange={() => handleCheckboxChange(movimiento.idMovimiento)}
                                    />
                                </td>
                                <td>#{movimiento.idMovimiento}</td>
                                <td>{movimiento.idArticulo}</td>
                                <td>{movimiento.iUnidades}</td>
                                <td>{getEstadoText(movimiento.idEstado)}</td>
                                <td>
                                    <select
                                        className="estado-select"
                                        value={movimiento.idEstado}
                                        onChange={(e) => updateEstado(movimiento.idMovimiento, e.target.value)}
                                        disabled={loading}
                                    >
                                        {estados.map(estado => (
                                            <option key={estado.idEstado} value={estado.idEstado}>
                                                {estado.sEstado}
                                            </option>
                                        ))}
                                    </select>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>

                <div className="modal-requests-footer">
                    <div className="total-movements">
                        {localPaquete.movimientos.length} movimientos en total
                    </div>
                    {loading && <div className="loading-indicator">Actualizando...</div>}
                </div>
            </div>
        </div>
    );
};

export default ModalRequest;