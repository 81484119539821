import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import config from '../config';
import { format, startOfDay, parseISO } from 'date-fns';
import { Circles } from 'react-loader-spinner';
import { useAuth } from '../AuthContext';
import UserPDFGenerator from './PDFGenUser';
import 'react-datepicker/dist/react-datepicker.css';
import "../styles/workloglist.css";

const UserWorkLogList = ({ isOpen, onClose }) => {
  const [marcajes, setMarcajes] = useState([]);
  const [filteredMarcajes, setFilteredMarcajes] = useState([]);
  const [startDate] = useState(startOfDay(new Date()));
  const [endDate] = useState(startOfDay(new Date()));
  const [ignoreDateFilter, setIgnoreDateFilter] = useState(false);
  const [selectedMarcajes, setSelectedMarcajes] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalDuration, setTotalDuration] = useState('00:00:00');
  const [selectAll, setSelectAll] = useState(false);

  const rowLimit = 25;

  const environment = process.env.NODE_ENV || 'development';
  const apiUrl = config[environment].API_BASE_URL;

  const { getAuthToken } = useAuth();
  const userId = localStorage.getItem('userId');

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  const handleModalClick = (e) => {
    if (e.target.classList.contains('modal-overlay')) {
      onClose();
    }
  };

  const fetchData = useCallback(async () => {
    try {
      const token = getAuthToken();
      const marcajesResponse = await axios.get(
        `${apiUrl}get_user_marcajes.php?userId=${userId}`, 
        { headers: { 'Authorization': `Bearer ${token}` } }
      );

      setMarcajes(marcajesResponse.data);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching data:', err);
      setError('No hay respuesta por parte del servidor, compruebe su conexión e inténtelo de nuevo.');
      setLoading(false);
    }
  }, [apiUrl, userId, getAuthToken]);

  const calculateSelectedDuration = useCallback(() => {
    const selectedMarcajesList = filteredMarcajes.filter(
      marcaje => selectedMarcajes[marcaje.idMarcaje]
    );

    const totalSeconds = selectedMarcajesList.reduce((total, marcaje) => {
      if (marcaje.sDuracion) {
        const [hours, minutes, seconds] = marcaje.sDuracion.split(':').map(Number);
        return total + hours * 3600 + minutes * 60 + seconds;
      }
      return total;
    }, 0);

    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }, [filteredMarcajes, selectedMarcajes]);

  const applyFilters = useCallback(() => {
    let filtered = marcajes;

    if (!ignoreDateFilter) {
      filtered = filtered.filter((marcaje) => {
        const marcajeDate = parseISO(marcaje.dFecha);
        return marcajeDate >= startOfDay(startDate) && marcajeDate <= startOfDay(endDate);
      });
    }

    setFilteredMarcajes(filtered);
  }, [marcajes, startDate, endDate, ignoreDateFilter]);

  useEffect(() => {
    if (isOpen) {
      fetchData();
    }
  }, [fetchData, isOpen]);

  useEffect(() => {
    applyFilters();
    setCurrentPage(1);
  }, [applyFilters]);

  useEffect(() => {
    setTotalDuration(calculateSelectedDuration());
  }, [selectedMarcajes, calculateSelectedDuration]);

  const handleIgnoreDateFilter = () => {
    setIgnoreDateFilter(!ignoreDateFilter);
  };

  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    const newSelectedMarcajes = {};
    filteredMarcajes.forEach(marcaje => {
      newSelectedMarcajes[marcaje.idMarcaje] = newSelectAll;
    });
    setSelectedMarcajes(newSelectedMarcajes);
  };

  const handleSelectMarcaje = (idMarcaje) => {
    setSelectedMarcajes(prev => ({
      ...prev,
      [idMarcaje]: !prev[idMarcaje]
    }));
  };

  const modalStyles = {
    overlay: {
      position: 'fixed',
      inset: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 50,
    },
    content: {
      backgroundColor: 'var(--bg-primary)',
      borderRadius: '8px',
      padding: '24px',
      width: '90%',
      maxWidth: '1000px',
      maxHeight: '90vh',
      overflow: 'auto',
      position: 'relative',
      boxShadow: 'var(--shadow-lg)',
      border: '1px solid var(--border-color)',
    },
    closeButton: {
      position: 'absolute',
      top: '12px',
      right: '12px',
      backgroundColor: 'transparent',
      border: 'none',
      color: 'var(--text-secondary)',
      cursor: 'pointer',
      padding: '8px',
      borderRadius: '4px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      transition: 'background-color 0.2s',
      width: '32px',
      height: '32px',
    },
    title: {
      color: 'var(--text-primary)',
      fontSize: '24px',
      fontWeight: '500',
      marginBottom: '24px',
      textAlign: 'center',
    },
    checkboxContainer: {
      display: 'flex',
      gap: '24px',
      justifyContent: 'center',
      marginBottom: '24px',
    },
    actionButtons: {
      display: 'flex',
      gap: '12px',
      justifyContent: 'center',
      marginBottom: '24px',
    },
    button: {
      backgroundColor: 'var(--bg-secondary)',
      color: 'var(--text-primary)',
      border: '1px solid var(--border-color)',
      padding: '8px 16px',
      borderRadius: '4px',
      cursor: 'pointer',
      transition: 'all 0.2s',
    },
    primaryButton: {
      backgroundColor: 'var(--color-primary)',
      color: 'white',
      border: 'none',
    }
  };

  if (!isOpen) return null;

  const totalPages = Math.ceil(filteredMarcajes.length / rowLimit);
  const startIndex = (currentPage - 1) * rowLimit;
  const endIndex = startIndex + rowLimit;
  const currentMarcajes = filteredMarcajes.slice(startIndex, endIndex);

  return (
    <div style={modalStyles.overlay} onClick={handleModalClick} className="modal-overlay">
      <div style={modalStyles.content}>
        {loading ? (
          <div className="w-full h-[60vh] flex justify-center items-center">
            <Circles height="80" width="80" color="var(--text-primary)" ariaLabel="circles-loading" />
          </div>
        ) : error ? (
          <div className="p-4" style={{ color: 'var(--color-error)' }}>{error}</div>
        ) : (
          <div className="admin-marcajes">
            <button 
              onClick={onClose} 
              style={modalStyles.closeButton}
              onMouseOver={e => e.target.style.backgroundColor = 'var(--bg-secondary)'}
              onMouseOut={e => e.target.style.backgroundColor = 'transparent'}
            >
              ✕
            </button>
            
            <h1 style={modalStyles.title}>Marcajes</h1>

            <div style={modalStyles.checkboxContainer}>
              <label className="flex items-center gap-2">
                <input
                  type="checkbox"
                  checked={ignoreDateFilter}
                  onChange={handleIgnoreDateFilter}
                  className="input-checkbox"
                />
                <span style={{ color: 'var(--text-primary)' }}>Todas las fechas</span>
              </label>
              <label className="flex items-center gap-2">
                <input
                  type="checkbox"
                  checked={selectAll}
                  onChange={handleSelectAll}
                  className="input-checkbox"
                />
                <span style={{ color: 'var(--text-primary)' }}>Seleccionar todos</span>
              </label>
            </div>

            <div style={modalStyles.actionButtons}>
              <UserPDFGenerator 
                selectedMarcajes={selectedMarcajes}
                currentMarcajes={currentMarcajes}
                totalDuration={totalDuration}
              />
            </div>

            <div style={{ color: 'var(--text-primary)', marginBottom: '16px', textAlign: 'center' }}>
              <strong>Duración total seleccionada:</strong> {totalDuration}
            </div>

            <table className="table-worklist">
              <thead>
                <tr className="tr-worklist">
                  <th className="th-worklist">Cliente</th>
                  <th className="th-worklist">Hora Inicio</th>
                  <th className="th-worklist">Hora Fin</th>
                  <th className="th-worklist">Duración</th>
                  <th className="th-worklist">Fecha</th>
                  <th className="th-worklist">Seleccionar</th>
                </tr>
              </thead>
              <tbody>
                {currentMarcajes.map((marcaje) => (
                  <tr key={marcaje.idMarcaje} className="tr-worklist">
                    <td className="td-worklist">{marcaje.sCliente}</td>
                    <td className="td-worklist">{marcaje.HoraIni}</td>
                    <td className="td-worklist">{marcaje.HoraFin || '-'}</td>
                    <td className="td-worklist">{marcaje.sDuracion || '-'}</td>
                    <td className="td-worklist">
                      {format(new Date(marcaje.dFecha), 'dd-MM-yyyy')}
                    </td>
                    <td className="td-worklist-input">
                      <input
                        type="checkbox"
                        checked={selectedMarcajes[marcaje.idMarcaje] || false}
                        onChange={() => handleSelectMarcaje(marcaje.idMarcaje)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div className="pagination">
              <button
                onClick={() => setCurrentPage(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Anterior
              </button>
              <span style={{ color: 'var(--text-primary)' }}>
                Página {currentPage} de {totalPages}
              </span>
              <button
                onClick={() => setCurrentPage(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                Siguiente
              </button>
            </div>
            <div style={{ color: 'var(--text-secondary)', textAlign: 'center' }}>
              Mostrando {currentMarcajes.length} de {filteredMarcajes.length} marcajes
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserWorkLogList;