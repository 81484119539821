import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Users, Plus, Trash2, X } from 'lucide-react';
import { Circles } from 'react-loader-spinner';
import config from '../config';
import '../styles/locations.css';

const UsersLocationModal = ({ isOpen, onClose, locationId, locationName }) => {
    const [assignedUsers, setAssignedUsers] = useState([]);
    const [availableUsers, setAvailableUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [addingUser, setAddingUser] = useState(false);
    const [selectedUser, setSelectedUser] = useState('');

    useEffect(() => {
        if (isOpen && locationId) {
            fetchAssignedUsers();
            fetchAvailableUsers();
        }
    }, [isOpen, locationId]);

    const fetchAssignedUsers = async () => {
        try {
            setLoading(true);
            const environment = process.env.NODE_ENV || 'development';
            const userId = localStorage.getItem('userId');

            const response = await axios.get(`${config[environment].API_BASE_URL}get_users_by_locations.php`, {
                params: {
                    userId,
                    locationId
                }
            });

            const usersData = Array.isArray(response.data) ? response.data : [];
            console.log("Usuarios asignados obtenidos:", usersData);
            setAssignedUsers(usersData);
            setError(null);
        } catch (err) {
            console.error('Error fetching assigned users:', err);
            setError('Error al cargar los usuarios asignados. Por favor, inténtelo de nuevo más tarde.');
            setAssignedUsers([]);
        } finally {
            setLoading(false);
        }
    };

    const fetchAvailableUsers = async () => {
        try {
            const environment = process.env.NODE_ENV || 'development';
            const userId = localStorage.getItem('userId');

            const response = await axios.get(`${config[environment].API_BASE_URL}get_users.php`, {
                params: { userId }
            });

            if (Array.isArray(response.data)) {
                const validUsers = response.data.filter(user => user && user.id);
                setAvailableUsers(validUsers);
            } else {
                setAvailableUsers([]);
            }
        } catch (err) {
            console.error('Error fetching available users:', err);
            setAvailableUsers([]);
        }
    };

    const handleAddUser = async () => {
        if (!selectedUser) {
            setError('Debe seleccionar un usuario');
            return;
        }

        try {
            setLoading(true);
            setError(null);

            const environment = process.env.NODE_ENV || 'development';
            const userId = localStorage.getItem('userId');

            const selectedUserObj = availableUsers.find(user => user.id === selectedUser);

            if (!selectedUserObj) {
                setError('Usuario no encontrado');
                setLoading(false);
                return;
            }

            const response = await axios.get(`${config[environment].API_BASE_URL}add_user_to_location.php`, {
                params: {
                    userId,
                    locationId,
                    idUsuario: selectedUserObj.id
                }
            });

            if (response.data && response.data.success) {
                const newUserLocation = {
                    idUsuario: selectedUserObj.id,
                    sUsuario: selectedUserObj.sNombreCompleto,
                    IdUbicacion: locationId
                };

                setAssignedUsers(prev => [...prev, newUserLocation]);
                setAddingUser(false);
                setSelectedUser('');
            } else {
                setError(response.data.message || 'Error al añadir el usuario a la ubicación');
            }
        } catch (err) {
            console.error('Error adding user to location:', err);
            setError('Error al añadir el usuario a la ubicación. Por favor, inténtelo de nuevo más tarde.');
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteUser = async (userIdToDelete) => {
        if (!userIdToDelete) {
            console.error('ID de usuario no válido para eliminar');
            setError('Error: ID de usuario no válido');
            return;
        }

        try {
            setLoading(true);
            setError(null);

            console.log(`Intentando eliminar usuario con ID: ${userIdToDelete}`);

            const environment = process.env.NODE_ENV || 'development';
            const currentUserId = localStorage.getItem('userId');

            console.log("Parámetros para eliminar:", {
                currentUserId,
                locationId,
                userIdToDelete
            });

            const response = await axios.get(`${config[environment].API_BASE_URL}remove_user_from_location.php`, {
                params: {
                    userId: currentUserId,
                    locationId: locationId,
                    idUsuario: userIdToDelete
                }
            });

            console.log("Respuesta del servidor:", response.data);

            if (response.data && response.data.success) {
                setAssignedUsers(prev => prev.filter(user => String(user.idUsuario) !== String(userIdToDelete)));
                setError(null);
            } else {
                setError(response.data.message || 'No se pudo eliminar el usuario. Por favor, inténtelo de nuevo.');
            }
        } catch (err) {
            console.error('Error eliminando usuario:', err);
            setError('Error al eliminar el usuario. Por favor, inténtelo de nuevo más tarde.');
        } finally {
            setLoading(false);
        }
    };

    const renderSelectedUserDetails = () => {
        if (!selectedUser) return null;

        const user = availableUsers.find(u => u.id === selectedUser);
        if (!user) return null;

        return (
            <div className="selected-user-details">
                <p><strong>Usuario seleccionado:</strong> {user.sNombreCompleto}</p>
                <p><strong>ID:</strong> {user.id}</p>
            </div>
        );
    };

    if (!isOpen) return null;

    return (
        <div className="locations-modal-overlay">
            <div className="locations-modal-content">
                <button className="locations-modal-close" onClick={onClose}><X size={24} /></button>
                <div className="locations-modal-header">
                    <Users size={24} />
                    <h2>Usuarios asignados a: {locationName}</h2>
                </div>

                {!addingUser && (
                    <div className="locations-modal-add-button-container">
                        <button className="locations-add-button" onClick={() => setAddingUser(true)}>
                            <Plus size={18} /> Asignar Usuario
                        </button>
                    </div>
                )}

                {addingUser && (
                    <div className="add-user-form">
                        <h3>Asignar nuevo usuario</h3>
                        <div className="form-group">
                            <label htmlFor="userId">Seleccione un usuario:</label>
                            <select
                                id="userId"
                                value={selectedUser}
                                onChange={(e) => setSelectedUser(e.target.value)}
                                disabled={loading}
                            >
                                <option value="">-- Seleccionar usuario --</option>
                                {availableUsers.map((user) => (
                                    <option key={`available-${user.id}`} value={user.id}>
                                        {user.sNombreCompleto}
                                    </option>
                                ))}
                            </select>
                        </div>

                        {renderSelectedUserDetails()}

                        <div className="form-actions">
                            <button
                                type="button"
                                onClick={() => setAddingUser(false)}
                                className="cancel-button"
                                disabled={loading}
                            >
                                Cancelar
                            </button>
                            <button
                                type="button"
                                onClick={handleAddUser}
                                className="submit-button"
                                disabled={loading || !selectedUser}
                            >
                                Asignar
                            </button>
                        </div>
                    </div>
                )}

                {loading && <div style={{ width: "100%", display: "flex", justifyContent: "center", margin: "20px 0" }}>
                    <Circles
                        height="80"
                        width="80"
                        color="#000000"
                        ariaLabel="circles-loading"
                        visible={true}
                    />
                </div>}

                {error && <p className="locations-error">{error}</p>}

                {!loading && !error && assignedUsers.length === 0 && (
                    <p className="locations-empty">No hay usuarios asignados a esta ubicación.</p>
                )}

                {!loading && !error && assignedUsers.length > 0 && (
                    <table className="locations-table">
                        <thead>
                        <tr>
                            <th>Usuario</th>
                            <th>Acciones</th>
                        </tr>
                        </thead>
                        <tbody>
                        {assignedUsers.map(user => (
                            <tr key={`assigned-${user.idUsuario}`}>
                                <td>{user.sUsuario}</td>
                                <td>
                                    <button
                                        className="delete-button"
                                        onClick={() => handleDeleteUser(user.idUsuario)}
                                        disabled={loading}
                                        title="Eliminar usuario"
                                    >
                                        <Trash2 size={18} />
                                    </button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                )}
            </div>
        </div>
    );
};

export default UsersLocationModal;