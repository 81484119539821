import { Link } from "react-router-dom";
import "../styles/choosingpage.css";
import React, { useState } from 'react';
import UserWorkLogList from './UserWorkLogList';

const ChoosingPage = () => {
    const userId = localStorage.getItem('userId');
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    
    if (!userId) {
        return (
            <div className="error-container">
                <p className="error-message">No se ha iniciado sesión. Por favor, inicie sesión nuevamente.</p>
            </div>
        );
    }

    return (
        <div className="enter-buttons">
            <Link to="/Saas/workdaylog">
                <button className="enter-button-2">Registro de jornada</button>
            </Link>
            <Link to="/Saas/materialrequests">
                <button className="enter-button-2">Petición de Material</button>
            </Link>
            <div className="logs">
                <button onClick={handleOpenModal} className="log-button">
                    Ver Marcajes
                </button>
            </div>

      <UserWorkLogList 
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
        </div>
    );
};

export default ChoosingPage;