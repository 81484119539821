import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import axios from 'axios';
import config from './config';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [userPermissions, setUserPermissions] = useState({});
  const [userRole, setUserRole] = useState(null);
  const [error, setError] = useState(null);
  const environment = process.env.NODE_ENV || 'development';
  const [licensedModules, setLicensedModules] = useState({});
  const [loadingLicenses, setLoadingLicenses] = useState(true);

  const getAuthToken = useCallback(() => {
    return localStorage.getItem('authToken');
  }, []);

  const fetchLicensedModules = useCallback(async () => {
    setLoadingLicenses(true);
    const empresaId = localStorage.getItem('empresaId');
    if (!empresaId) {
      setLoadingLicenses(false);
      return;
    }

    try {
      const token = getAuthToken();
      const response = await axios.get(
          `${config[environment].API_BASE_URL}get_licenses.php?empresaId=${empresaId}`,
          { headers: { 'Authorization': `Bearer ${token}` } }
      );
      const modules = {};
      setLicensedModules(modules)
      response.data.forEach(license => {
        modules[license.sCodigo] = {
          isActive: license.bActivo === 1,
          name: license.sNombre,
          expirationDate: license.dFechaExpiracion
        };
      });

      setLicensedModules(modules);
    } catch (error) {
      console.error('Error al cargar licencias:', error);
    } finally {
      setLoadingLicenses(false);
    }
  }, [environment, getAuthToken]);

  useEffect(() => {
    if (isAuthenticated) {
      fetchLicensedModules();
    }
  }, [isAuthenticated, fetchLicensedModules]);

  const checkModuleLicense = useCallback((moduleCode) => {
    if (loadingLicenses) return true; // Permitir durante la carga
    return licensedModules[moduleCode]?.isActive || false;
  }, [licensedModules, loadingLicenses]);

  const fetchUserPermissions = useCallback(async (token, userId) => {
    if (userId && token) {
      try {
        const response = await axios.get(`${config[environment].PHP_GET_USER_PERMISSIONS_URL}?userId=${userId}`, {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        setUserPermissions(response.data);
        return response.data;
      } catch (error) {
        console.error('Error fetching user permissions:', error);
        if (error.response && error.response.status === 401) {
          throw new Error('Authentication failed while fetching permissions');
        }
        return {};
      }
    }
    return {};
  }, [environment]);

  const logout = useCallback(() => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('userId');
    localStorage.removeItem('userRole');
    localStorage.removeItem('empresaId');
    localStorage.removeItem('encryptedDbName');
    localStorage.removeItem('selectedClientId');
    localStorage.removeItem('loginStatus');
    localStorage.removeItem('sUsuario');
    localStorage.removeItem('clientId')
    setIsAuthenticated(false);
    setUserPermissions({});
    setUserRole(null);
    setError(null);
    setIsLoading(false);
  }, []);

  const checkAuthStatus = useCallback(async () => {
    const token = localStorage.getItem('authToken');
    const userId = localStorage.getItem('userId');
    const storedRole = localStorage.getItem('userRole');
    if (token && userId && storedRole) {
      try {
        const response = await axios.post(config[environment].PHP_VALIDATE_TOKEN_URL, { token, userId });

        if (response.data.valid) {
          setIsAuthenticated(true);
          setUserRole(storedRole);
          await fetchUserPermissions(token, userId);
          setError(null);
        } else {
          console.log('Token invalid, logging out. Reason:', response.data.message);
          logout();
          setError('Sesión expirada. Por favor, inicie sesión de nuevo.');
        }
      } catch (error) {
        console.error('Error validating token:', error);
        if (error.response) {
          console.error('Server error:', error.response.data);
        }
        logout();
        setError('Error de autenticación. Por favor, inicie sesión de nuevo.');
      }
    } else {
      console.log('No token, userId, or role found, not authenticated');
      logout();
    }
    setIsLoading(false);
  }, [environment, fetchUserPermissions, logout]);

  useEffect(() => {
    checkAuthStatus();
  }, [checkAuthStatus]);

  const login = useCallback(async (token, userId, role, empresaId) => {
    //console.log('Login attempt with:', { token, userId, role, empresaId });
    if (!token || !userId || role === undefined || !empresaId) {
      console.error('Invalid login data:', { token, userId, role, empresaId });
      throw new Error('Datos de inicio de sesión inválidos: se deben proporcionar token, userId, role y empresaId');
    }
    try {
      localStorage.setItem('authToken', token);
      localStorage.setItem('userId', userId.toString());
      localStorage.setItem('userRole', role);
      localStorage.setItem('empresaId', empresaId.toString());
      setIsAuthenticated(true);
      setUserRole(role);
      const permissions = await fetchUserPermissions(token, userId);
      setError(null);
      setIsLoading(false);
      return permissions;
    } catch (error) {
      console.error('Error de inicio de sesión:', error);
      logout();
      setError('Error al iniciar sesión. Por favor, inténtelo de nuevo.');
      throw error;
    }
  }, [fetchUserPermissions, logout]);

  const getDatabaseName = useCallback(async () => {
    const empresaId = localStorage.getItem('empresaId');
    if (!empresaId) {
      throw new Error('ID de empresa no encontrado');
    }
    try {
      const response = await axios.post(config[environment].PHP_GET_DB_NAME_URL, { empresaId }, {
        headers: { 'Authorization': `Bearer ${localStorage.getItem('authToken')}` }
      });
      return response.data.dbName;
    } catch (error) {
      console.error('Error al obtener el nombre de la base de datos:', error);
      throw error;
    }
  }, [environment]);

  const checkRoutePermission = useCallback((route) => {
    if (!isAuthenticated) return false;

    const normalizedRoute = route.startsWith('http') ? new URL(route).pathname : route;
    const permission = userPermissions[normalizedRoute];

    if (!permission) return false;

    return permission.iL || permission.iC || permission.iI || permission.iM || permission.iB;
  }, [isAuthenticated, userPermissions]);

  return (
    <AuthContext.Provider value={{
      isAuthenticated,
      isLoading,
      login,
      logout,
      checkAuthStatus,
      userPermissions,
      checkRoutePermission,
      userRole,
      error,
      getAuthToken,
      getDatabaseName,
      checkModuleLicense,
      licensedModules
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);