import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { X, Save, Trash2, Calendar, Clock, User, Users, Briefcase, Repeat, AlertCircle } from 'lucide-react';
import config from '../config';
import '../styles/modal.css';

const ModalEvent = ({ userId, isOpen, onClose, onEventsChange, event }) => {
    const [formData, setFormData] = useState({
        titulo: '',
        fecha: new Date().toISOString().split('T')[0],
        horaInicio: '09:00',
        horaFin: '10:00',
        idCliente: '',
        nota: '',
        asignacionTipo: 'operario',
        idUsuario: '',
        idGrupo: '',
        esSerie: false,
        patronRepeticion: 'SEMANAL',
        intervalo: 1,
        fechaFinRepeticion: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
        diasSemana: {
            L: false,
            M: false,
            X: false,
            J: false,
            V: false,
            S: false,
            D: false
        },
        diaMes: 1
    });

    const [isEditing, setIsEditing] = useState(false);
    const [usuarios, setUsuarios] = useState([]);
    const [clientes, setClientes] = useState([]);
    const [grupos, setGrupos] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    const environment = process.env.NODE_ENV || 'development';
    const apiBaseUrl = config[environment].API_BASE_URL;

    useEffect(() => {
        if (!isOpen) return;

        if (event) {
            setIsEditing(true);

            let diasSemanaObj = {
                L: false,
                M: false,
                X: false,
                J: false,
                V: false,
                S: false,
                D: false
            };

            if (event.esSerie && event.patronRepeticion === 'SEMANAL' && event.repeticionDias) {
                const dias = event.repeticionDias.split('');
                if (dias.includes('1')) diasSemanaObj.L = true;
                if (dias.includes('2')) diasSemanaObj.M = true;
                if (dias.includes('3')) diasSemanaObj.X = true;
                if (dias.includes('4')) diasSemanaObj.J = true;
                if (dias.includes('5')) diasSemanaObj.V = true;
                if (dias.includes('6')) diasSemanaObj.S = true;
                if (dias.includes('7')) diasSemanaObj.D = true;
            }

            setFormData({
                titulo: event.titulo || '',
                fecha: event.fecha || new Date().toISOString().split('T')[0],
                horaInicio: event.horaInicio || '09:00',
                horaFin: event.horaFin || '10:00',
                idCliente: event.idCliente || '',
                nota: event.nota || '',
                asignacionTipo: event.esGrupo ? 'grupo' : 'operario',
                idUsuario: event.idUsuario || '',
                idGrupo: event.idGrupo || '',
                esSerie: event.esSerie || false,
                patronRepeticion: event.patronRepeticion || 'SEMANAL',
                intervalo: event.intervalo || 1,
                fechaFinRepeticion: event.fechaFin || new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
                diasSemana: diasSemanaObj,
                diaMes: event.diaMes || 1
            });
        } else {
            setIsEditing(false);
            setFormData({
                titulo: '',
                fecha: new Date().toISOString().split('T')[0],
                horaInicio: '09:00',
                horaFin: '10:00',
                idCliente: '',
                nota: '',
                asignacionTipo: 'operario',
                idUsuario: '',
                idGrupo: '',
                esSerie: false,
                patronRepeticion: 'SEMANAL',
                intervalo: 1,
                fechaFinRepeticion: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
                diasSemana: {
                    L: false,
                    M: false,
                    X: false,
                    J: false,
                    V: false,
                    S: false,
                    D: false
                },
                diaMes: 1
            });
        }

        cargarUsuarios();
        cargarClientes();
        cargarGrupos();
    }, [isOpen, event, environment, apiBaseUrl]);

    const cargarUsuarios = async () => {
        const userId = localStorage.getItem('userId');
        try {
            const response = await axios.get(
                `${config[environment].PHP_GET_USERS_URL}?userId=${userId}`
            );
            setUsuarios(response.data);
        } catch (err) {
            console.error('Error al cargar usuarios:', err);
            setError('Error al cargar los usuarios');
        }
    };

    const cargarClientes = async () => {
        const userId = localStorage.getItem('userId');
        try {
            const response = await axios.get(
                `${config[environment].PHP_GET_CLIENTS_A_URL}`, {
                    params: {
                        userId: userId
                    }
                }
            );
            setClientes(response.data);
        } catch (err) {
            console.error('Error al cargar clientes:', err);
            setError('Error al cargar los clientes');
        }
    };

    const cargarGrupos = async () => {
        const userId = localStorage.getItem('userId');
        try {
            const response = await axios.get(
                `${config[environment].PHP_GET_GROUPS_URL}?userId=${userId}`
            );
            setGrupos(response.data);
        } catch (err) {
            console.error('Error al cargar grupos:', err);
            setError('Error al cargar los grupos');
        }
    };

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;

        if (name.startsWith('diaSemana-')) {
            const dia = name.split('-')[1];
            setFormData(prevState => ({
                ...prevState,
                diasSemana: {
                    ...prevState.diasSemana,
                    [dia]: checked
                }
            }));
        } else if (type === 'checkbox') {
            setFormData(prevState => ({
                ...prevState,
                [name]: checked
            }));
        } else {
            setFormData(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!formData.titulo) {
            setError('El título es obligatorio');
            return;
        }

        if (!formData.idCliente) {
            setError('Debe seleccionar un cliente');
            return;
        }

        if (formData.asignacionTipo === 'operario' && !formData.idUsuario) {
            setError('Debe seleccionar un operario');
            return;
        }

        if (formData.asignacionTipo === 'grupo' && !formData.idGrupo) {
            setError('Debe seleccionar un grupo');
            return;
        }

        if (formData.esSerie && formData.patronRepeticion === 'SEMANAL') {
            const alMenosUnDia = Object.values(formData.diasSemana).some(dia => dia);
            if (!alMenosUnDia) {
                setError('Debe seleccionar al menos un día de la semana para eventos semanales');
                return;
            }
        }

        let dataToSend = {
            userId: localStorage.getItem('userId'),
            titulo: formData.titulo,
            fecha: formData.fecha,
            horaInicio: formData.horaInicio,
            horaFin: formData.horaFin,
            idCliente: formData.idCliente,
            nota: formData.nota,
            esGrupo: formData.asignacionTipo === 'grupo',
            idUsuario: formData.asignacionTipo === 'operario' ? formData.idUsuario : null,
            idGrupo: formData.asignacionTipo === 'grupo' ? formData.idGrupo : null,
            esSerie: formData.esSerie
        };

        if (formData.esSerie) {
            dataToSend = {
                ...dataToSend,
                patronRepeticion: formData.patronRepeticion,
                intervalo: formData.intervalo,
                fechaFinRepeticion: formData.fechaFinRepeticion
            };

            if (formData.patronRepeticion === 'SEMANAL') {
                let diasString = '';
                if (formData.diasSemana.L) diasString += '1';
                if (formData.diasSemana.M) diasString += '2';
                if (formData.diasSemana.X) diasString += '3';
                if (formData.diasSemana.J) diasString += '4';
                if (formData.diasSemana.V) diasString += '5';
                if (formData.diasSemana.S) diasString += '6';
                if (formData.diasSemana.D) diasString += '7';

                dataToSend.repeticionDias = diasString;
            }

            if (formData.patronRepeticion === 'MENSUAL') {
                dataToSend.diaMes = formData.diaMes;
            }
        }

        if (isEditing && event) {
            dataToSend.idEvento = event.id;
            if (event.esSerie) {
                dataToSend.idSerie = event.idSerie;
            }
        }

        setLoading(true);
        try {
            let response;

            if (isEditing) {
                response = await axios.post(`${apiBaseUrl}update_event.php`, dataToSend);
                setSuccess('Evento actualizado correctamente');
            } else {
                response = await axios.post(`${apiBaseUrl}create_event.php`, dataToSend);
                setSuccess('Evento creado correctamente');
            }

            if (onEventsChange) {
                onEventsChange();
            }

            setTimeout(() => {
                onClose();
            }, 1500);
        } catch (err) {
            console.error('Error al guardar el evento:', err);
            setError('Error al guardar el evento');
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteEvent = async () => {
        if (!isEditing || !event) return;

        if (!window.confirm('¿Está seguro de que desea eliminar este evento?')) {
            return;
        }

        setLoading(true);
        try {
            const dataToSend = {
                userId: localStorage.getItem('userId'),
                idEvento: event.id
            };

            if (event.esSerie) {
                const eliminarSerie = window.confirm(
                    'Este es un evento recurrente. ¿Desea eliminar toda la serie?\n' +
                    'Aceptar: Eliminar toda la serie\n' +
                    'Cancelar: Eliminar solo este evento'
                );

                if (eliminarSerie) {
                    dataToSend.eliminarSerie = true;
                    dataToSend.idSerie = event.idSerie;
                }
            }

            await axios.post(`${apiBaseUrl}delete_event.php`, dataToSend);

            setSuccess('Evento eliminado correctamente');

            if (onEventsChange) {
                onEventsChange();
            }

            setTimeout(() => {
                onClose();
            }, 1500);
        } catch (err) {
            console.error('Error al eliminar el evento:', err);
            setError('Error al eliminar el evento');
        } finally {
            setLoading(false);
        }
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-container">
                <div className="modal-header">
                    <h2>{isEditing ? 'Editar Evento' : 'Crear Nuevo Evento'}</h2>
                    <button className="close-button" onClick={onClose}>
                        <X size={20} />
                    </button>
                </div>

                {loading ? (
                    <div className="modal-loading">Cargando...</div>
                ) : (
                    <div className="modal-event-content">
                        {error && (
                            <div className="modal-error">
                                <AlertCircle size={18} />
                                <span>{error}</span>
                            </div>
                        )}

                        {success && (
                            <div className="modal-success">
                                <span>{success}</span>
                            </div>
                        )}

                        <form onSubmit={handleSubmit} className="event-form">
                            <div className="form-section">
                                <h3>Información General</h3>

                                <div className="form-group">
                                    <label htmlFor="titulo">
                                        Título <span className="campo-obligatorio">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        id="titulo"
                                        name="titulo"
                                        value={formData.titulo}
                                        onChange={handleInputChange}
                                        placeholder="Título del evento"
                                        required
                                    />
                                </div>

                                <div className="form-row">
                                    <div className="form-group">
                                        <label htmlFor="fecha">
                                            <Calendar size={16} /> Fecha <span className="campo-obligatorio">*</span>
                                        </label>
                                        <input
                                            type="date"
                                            id="fecha"
                                            name="fecha"
                                            value={formData.fecha}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="horaInicio">
                                            <Clock size={16} /> Hora inicio <span className="campo-obligatorio">*</span>
                                        </label>
                                        <input
                                            type="time"
                                            id="horaInicio"
                                            name="horaInicio"
                                            value={formData.horaInicio}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="horaFin">
                                            <Clock size={16} /> Hora fin <span className="campo-obligatorio">*</span>
                                        </label>
                                        <input
                                            type="time"
                                            id="horaFin"
                                            name="horaFin"
                                            value={formData.horaFin}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="idCliente">
                                        <Briefcase size={16} /> Cliente <span className="campo-obligatorio">*</span>
                                    </label>
                                    <select
                                        id="idCliente"
                                        name="idCliente"
                                        value={formData.idCliente}
                                        onChange={handleInputChange}
                                        required
                                    >
                                        <option value="">Seleccionar cliente</option>
                                        {clientes.map(cliente => (
                                            <option key={cliente.idCliente} value={cliente.idCliente}>
                                                {cliente.sCliente}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="nota">Notas adicionales</label>
                                    <textarea
                                        id="nota"
                                        name="nota"
                                        value={formData.nota}
                                        onChange={handleInputChange}
                                        placeholder="Añadir notas o instrucciones..."
                                        rows={3}
                                    />
                                </div>
                            </div>

                            <div className="form-section">
                                <h3>Asignación</h3>

                                <div className="form-group radio-group">
                                    <div className="radio-option">
                                        <input
                                            type="radio"
                                            id="operario"
                                            name="asignacionTipo"
                                            value="operario"
                                            checked={formData.asignacionTipo === 'operario'}
                                            onChange={handleInputChange}
                                        />
                                        <label htmlFor="operario">
                                            <User size={16} /> Asignar a operario
                                        </label>
                                    </div>

                                    <div className="radio-option">
                                        <input
                                            type="radio"
                                            id="grupo"
                                            name="asignacionTipo"
                                            value="grupo"
                                            checked={formData.asignacionTipo === 'grupo'}
                                            onChange={handleInputChange}
                                        />
                                        <label htmlFor="grupo">
                                            <Users size={16} /> Asignar a grupo
                                        </label>
                                    </div>
                                </div>

                                {formData.asignacionTipo === 'operario' ? (
                                    <div className="form-group">
                                        <label htmlFor="idUsuario">
                                            Operario <span className="campo-obligatorio">*</span>
                                        </label>
                                        <select
                                            id="idUsuario"
                                            name="idUsuario"
                                            value={formData.idUsuario}
                                            onChange={handleInputChange}
                                            required={formData.asignacionTipo === 'operario'}
                                        >
                                            <option value="">Seleccionar operario</option>
                                            {usuarios.map(usuario => (
                                                <option key={usuario.id} value={usuario.id}>
                                                    {usuario.sNombreCompleto}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                ) : (
                                    <div className="form-group">
                                        <label htmlFor="idGrupo">
                                            Grupo <span className="campo-obligatorio">*</span>
                                        </label>
                                        <select
                                            id="idGrupo"
                                            name="idGrupo"
                                            value={formData.idGrupo}
                                            onChange={handleInputChange}
                                            required={formData.asignacionTipo === 'grupo'}
                                        >
                                            <option value="">Seleccionar grupo</option>
                                            {grupos.map(grupo => (
                                                <option key={grupo.id} value={grupo.id}>
                                                    Grupo {grupo.id} ({grupo.numUsuarios} operarios)
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                )}
                            </div>

                            <div className="form-section">
                                <div className="form-header-with-toggle">
                                    <h3>Evento Recurrente</h3>
                                    <div className="toggle-wrapper">
                                        <input
                                            type="checkbox"
                                            id="esSerie"
                                            name="esSerie"
                                            checked={formData.esSerie}
                                            onChange={handleInputChange}
                                            className="toggle-checkbox"
                                        />
                                        <label htmlFor="esSerie" className="toggle-label">
                                            <Repeat size={16} /> {formData.esSerie ? 'Activado' : 'Desactivado'}
                                        </label>
                                    </div>
                                </div>

                                {formData.esSerie && (
                                    <div className="recurring-options">
                                        <div className="form-group">
                                            <label htmlFor="patronRepeticion">Patrón de repetición</label>
                                            <select
                                                id="patronRepeticion"
                                                name="patronRepeticion"
                                                value={formData.patronRepeticion}
                                                onChange={handleInputChange}
                                            >
                                                <option value="DIARIO">Diario</option>
                                                <option value="SEMANAL">Semanal</option>
                                                <option value="MENSUAL">Mensual</option>
                                                <option value="ANUAL">Anual</option>
                                            </select>
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="intervalo">
                                                Intervalo ({getIntervaloText(formData.patronRepeticion)})
                                            </label>
                                            <input
                                                type="number"
                                                id="intervalo"
                                                name="intervalo"
                                                value={formData.intervalo}
                                                onChange={handleInputChange}
                                                min="1"
                                                max="99"
                                            />
                                        </div>

                                        {formData.patronRepeticion === 'SEMANAL' && (
                                            <div className="form-group dias-semana">
                                                <label>Días de repetición</label>
                                                <div className="dias-semana-options">
                                                    {Object.entries({
                                                        'L': 'Lunes',
                                                        'M': 'Martes',
                                                        'X': 'Miércoles',
                                                        'J': 'Jueves',
                                                        'V': 'Viernes',
                                                        'S': 'Sábado',
                                                        'D': 'Domingo'
                                                    }).map(([key, dia]) => (
                                                        <div key={key} className="dia-option">
                                                            <input
                                                                type="checkbox"
                                                                id={`diaSemana-${key}`}
                                                                name={`diaSemana-${key}`}
                                                                checked={formData.diasSemana[key]}
                                                                onChange={handleInputChange}
                                                            />
                                                            <label htmlFor={`diaSemana-${key}`}>{key}</label>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        )}

                                        {formData.patronRepeticion === 'MENSUAL' && (
                                            <div className="form-group">
                                                <label htmlFor="diaMes">Día del mes</label>
                                                <input
                                                    type="number"
                                                    id="diaMes"
                                                    name="diaMes"
                                                    value={formData.diaMes}
                                                    onChange={handleInputChange}
                                                    min="1"
                                                    max="31"
                                                />
                                            </div>
                                        )}

                                        <div className="form-group">
                                            <label htmlFor="fechaFinRepeticion">Fecha de finalización</label>
                                            <input
                                                type="date"
                                                id="fechaFinRepeticion"
                                                name="fechaFinRepeticion"
                                                value={formData.fechaFinRepeticion}
                                                onChange={handleInputChange}
                                                min={formData.fecha}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className="form-actions">
                                {isEditing && (
                                    <button
                                        type="button"
                                        className="btn-delete"
                                        onClick={handleDeleteEvent}
                                        disabled={loading}
                                    >
                                        <Trash2 size={16} /> Eliminar
                                    </button>
                                )}

                                <button
                                    type="submit"
                                    className="btn-save"
                                    disabled={loading}
                                >
                                    <Save size={16} /> {isEditing ? 'Actualizar' : 'Guardar'}
                                </button>
                            </div>
                        </form>
                    </div>
                )}
            </div>
        </div>
    );
};

function getIntervaloText(patron) {
    switch (patron) {
        case 'DIARIO':
            return 'días';
        case 'SEMANAL':
            return 'semanas';
        case 'MENSUAL':
            return 'meses';
        case 'ANUAL':
            return 'años';
        default:
            return '';
    }
}

export default ModalEvent;