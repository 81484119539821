import React, { useState } from 'react';
import axios from 'axios';
import config from '../config';
import { useAuth } from '../AuthContext';
import { format } from 'date-fns';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-time-picker/dist/TimePicker.css';
import '../styles/editMarcajeForm.css';

const EditMarcajeForm = ({ marcaje, onEditComplete, users, clients }) => {
    const [formData, setFormData] = useState(() => {
        let fechaMarcaje;
        try {
            if (marcaje.dFecha instanceof Date) {
                fechaMarcaje = marcaje.dFecha;
            } else if (typeof marcaje.dFecha === 'string') {
                fechaMarcaje = new Date(marcaje.dFecha);
            } else {
                fechaMarcaje = new Date(String(marcaje.dFecha));
            }
            
            if (isNaN(fechaMarcaje.getTime())) {
                fechaMarcaje = new Date();
            }
        } catch (err) {
            console.error('Error parsing date in form:', err);
            fechaMarcaje = new Date();
        }
        
        return {
            ...marcaje,
            dFecha: fechaMarcaje,
            HoraIni: marcaje.HoraIni || '',
            HoraFin: marcaje.HoraFin || '',
            idTipoIncidencia: marcaje.idTipoIncidencia || 1
        };
    });
    const [error, setError] = useState(null);
    const { getAuthToken } = useAuth();

    const environment = process.env.NODE_ENV || 'development';
    const updateMarcajeUrl = `${config[environment].API_BASE_URL}update_marcaje.php`;

    const tiposIncidencia = [
        { id: 1, nombre: 'Jornada Laboral' },
        { id: 2, nombre: 'Pausa Almuerzo' },
        { id: 3, nombre: 'Descanso' },
        { id: 4, nombre: 'Otros' }
    ];

    const handleDateChange = (date) => {
        setFormData(prevData => ({
            ...prevData,
            dFecha: date
        }));
    };

    const handleTimeChange = (field, value) => {
        setFormData(prevData => ({
            ...prevData,
            [field]: value
        }));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const calculateDuration = () => {
        if (!formData.HoraIni || !formData.HoraFin) return null;

        const [startHours, startMinutes, startSeconds] = formData.HoraIni.split(':').map(Number);
        const [endHours, endMinutes, endSeconds] = formData.HoraFin.split(':').map(Number);

        let diffSeconds = endSeconds - startSeconds;
        let diffMinutes = endMinutes - startMinutes;
        let diffHours = endHours - startHours;

        if (diffSeconds < 0) {
            diffSeconds += 60;
            diffMinutes -= 1;
        }
        if (diffMinutes < 0) {
            diffMinutes += 60;
            diffHours -= 1;
        }
        if (diffHours < 0) {
            diffHours += 24;
        }

        return `${diffHours.toString().padStart(2, '0')}:${diffMinutes.toString().padStart(2, '0')}:${diffSeconds.toString().padStart(2, '0')}`;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);

        let updatedFormData = { ...formData };
        if (formData.HoraIni && formData.HoraFin) {
            updatedFormData.sDuracion = calculateDuration();
        }

        try {
            const token = getAuthToken();
            const userId = localStorage.getItem('userId');
            
            const submissionData = {
                ...updatedFormData,
                userId,
                dFecha: format(updatedFormData.dFecha, 'yyyy-MM-dd')
            };

            const response = await axios.post(updateMarcajeUrl, submissionData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.data.success) {
                onEditComplete(updatedFormData);
            } else {
                throw new Error(response.data.message || 'Error al actualizar el marcaje');
            }
        } catch (error) {
            console.error('Error al actualizar el marcaje:', error);
            setError('Error al actualizar el marcaje. Por favor, inténtelo de nuevo.');
        }
    };

    return (
        <form onSubmit={handleSubmit} className="edit-marcaje-form">
            <h2>Editar Marcaje</h2>
            
            <div className="form-group">
                <label htmlFor="idUsuario">Operario:</label>
                <select
                    id="idUsuario"
                    name="idUsuario"
                    value={formData.idUsuario}
                    onChange={handleChange}
                    className="form-select"
                    disabled
                >
                    {Array.isArray(users) && users.length > 0 ? 
                        users.filter(user => user && user.idUsuario).map(user => (
                            <option key={`user-${user.idUsuario}`} value={user.idUsuario}>
                                {user.sNombre_Completo || `Usuario ${user.idUsuario}`}
                            </option>
                        ))
                        :
                        <option value={formData.idUsuario}>
                            {formData.sNombre_Completo || `Usuario ${formData.idUsuario}`}
                        </option>
                    }
                </select>
            </div>

            <div className="form-group">
                <label htmlFor="idTipoIncidencia">Tipo de Incidencia:</label>
                <select
                    id="idTipoIncidencia"
                    name="idTipoIncidencia"
                    value={formData.idTipoIncidencia}
                    onChange={handleChange}
                    className="form-select"
                >
                    {tiposIncidencia.map(tipo => (
                        <option key={`tipo-${tipo.id}`} value={tipo.id}>
                            {tipo.nombre}
                        </option>
                    ))}
                </select>
            </div>

            <div className="form-group">
                <label htmlFor="idCliente">Cliente:</label>
                <select
                    id="idCliente"
                    name="idCliente"
                    value={formData.idCliente}
                    onChange={handleChange}
                    className="form-select"
                >
                    {Array.isArray(clients) && clients.length > 0 ? 
                        clients.filter(client => client && client.idCliente).map(client => (
                            <option key={`cliente-${client.idCliente}`} value={client.idCliente}>
                                {client.sCliente || `Cliente ${client.idCliente}`}
                            </option>
                        ))
                        :
                        <option value={formData.idCliente}>
                            {formData.sCliente || `Cliente ${formData.idCliente}`}
                        </option>
                    }
                </select>
            </div>

            <div className="form-group">
                <label htmlFor="dFecha">Fecha:</label>
                <DatePicker
                    selected={formData.dFecha}
                    onChange={handleDateChange}
                    dateFormat="dd/MM/yyyy"
                    className="form-control"
                />
            </div>

            <div className="form-row">
                <div className="form-group">
                    <label htmlFor="HoraIni">Hora Inicio:</label>
                    <input
                        type="time"
                        id="HoraIni"
                        name="HoraIni"
                        value={formData.HoraIni}
                        onChange={(e) => handleTimeChange('HoraIni', e.target.value)}
                        className="form-control"
                        step="1"
                        required
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="HoraFin">Hora Fin:</label>
                    <input
                        type="time"
                        id="HoraFin"
                        name="HoraFin"
                        value={formData.HoraFin || ''}
                        onChange={(e) => handleTimeChange('HoraFin', e.target.value)}
                        className="form-control"
                        step="1"
                    />
                </div>
            </div>

            {formData.HoraIni && formData.HoraFin && (
                <div className="form-group">
                    <label>Duración Calculada:</label>
                    <div className="duration-display">{calculateDuration()}</div>
                </div>
            )}

            {error && <p className="error-message">{error}</p>}
            
            <div className="form-actions">
                <button type="submit" className="btn-save">Guardar Cambios</button>
                <button type="button" className="btn-cancel" onClick={() => onEditComplete(marcaje)}>
                    Cancelar
                </button>
            </div>
        </form>
    );
};

export default EditMarcajeForm;