import React, { useState } from 'react';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import axios from 'axios';
import config from '../config';
import { formatDate, formatTime, validateWorkLog } from './dateTimeUtils';
import '../styles/pdfgenerator.css';

const hexToRgb = (hex) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16)
    ] : [0, 0, 0];
};

const PDFGenerator = ({
    selectedMarcajes,
    currentMarcajes,
    totalDuration
}) => {
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [showPreview, setShowPreview] = useState(false);
    const [pdfUrl, setPdfUrl] = useState(null);

    const generatePDFDocument = async () => {
        // const getBase64Image = async (url) => {
        //     try {
        //         const response = await fetch(url);
        //         const blob = await response.blob();
        //         return new Promise((resolve, reject) => {
        //             const reader = new FileReader();
        //             reader.onload = () => resolve(reader.result);
        //             reader.onerror = reject;
        //             reader.readAsDataURL(blob);
        //         });
        //     } catch (error) {
        //         console.error('Error al obtener imagen en base64:', error);
        //         return null;
        //     }
        // };
        try {
            const empresaId = localStorage.getItem('empresaId');
            if (!empresaId) {
                throw new Error('No se encontró el ID de la empresa');
            }

            const environment = process.env.NODE_ENV || 'development';
            const response = await axios.get(`${config[environment].API_BASE_URL}get_pdf_config.php`, {
                params: { empresaId }
            });

            if (!response.data.success) {
                throw new Error(response.data.message);
            }

            const pdfConfig = response.data.config;
            const doc = new jsPDF({
                orientation: pdfConfig.page.orientation,
                unit: 'mm',
                format: pdfConfig.page.format,
                margins: pdfConfig.page.margins
            });

            const selectedRows = currentMarcajes
                .filter(marcaje => selectedMarcajes[marcaje.idMarcaje])
                .map(validateWorkLog);

            if (selectedRows.length === 0) {
                throw new Error("Por favor, seleccione al menos una fila para generar el PDF");
            }

            doc.setFont(pdfConfig.header.company_name.style.font);
            
            const logoConfig = pdfConfig.header.logo;
            
            const addLogoToDocument = async () => {
                try {
                    const environment = process.env.NODE_ENV || 'development';
                    const empresaId = localStorage.getItem('empresaId');
                    const logoUrl = `${config[environment].API_BASE_URL}get_company_logo.php?empresaId=${empresaId}`;
                    
                    const response = await fetch(logoUrl);
                    
                    if (response.ok) {
                        const blob = await response.blob();
                        const reader = new FileReader();
                        
                        const logoDataUrl = await new Promise((resolve) => {
                            reader.onload = () => resolve(reader.result);
                            reader.readAsDataURL(blob);
                        });
                        
                        doc.addImage(
                            logoDataUrl,
                            'AUTO',
                            logoConfig.position.x,
                            logoConfig.position.y,
                            logoConfig.size.width,
                            logoConfig.size.height
                        );
                    } else {
                        throw new Error('Logo no encontrado');
                    }
                } catch (err) {
                    console.error('Error al cargar la imagen del logo:', err);
                    doc.setFillColor(...hexToRgb(logoConfig.style.background));
                    doc.setDrawColor(...hexToRgb(logoConfig.style.border));
                    doc.rect(
                        logoConfig.position.x,
                        logoConfig.position.y,
                        logoConfig.size.width,
                        logoConfig.size.height,
                        'FD'
                    );
                }
            };
            
            await addLogoToDocument();

            const companyNameConfig = pdfConfig.header.company_name;
            doc.setFontSize(companyNameConfig.style.size);
            doc.setFont(companyNameConfig.style.font, companyNameConfig.style.weight);
            doc.text(
                companyNameConfig.text,
                companyNameConfig.position.x,
                companyNameConfig.position.y
            );
            
            const companyAddressConfig = pdfConfig.header.company_address;
            if (companyAddressConfig) {
                doc.setFontSize(companyAddressConfig.style.size);
                doc.setFont(companyAddressConfig.style.font, 'normal');
                
                const cifText = pdfConfig.metadata && pdfConfig.metadata.address ? 
                    pdfConfig.metadata.address : 'CIF';
                    
                doc.text(
                    cifText,
                    companyAddressConfig.position.x,
                    companyAddressConfig.position.y
                );
            }

            const separatorConfig = pdfConfig.header.separator_line;
            doc.setDrawColor(...hexToRgb(separatorConfig.style.color));
            doc.line(
                pdfConfig.page.margins.left,
                separatorConfig.position.y,
                doc.internal.pageSize.width - pdfConfig.page.margins.right,
                separatorConfig.position.y
            );

            const tableConfig = pdfConfig.table;
            const tableHeaders = [['Usuario', 'Fecha', 'Hora Inicio', 'Hora Fin', 'Duración']];
            const tableData = selectedRows.map(marcaje => [
                marcaje.sNombre_Completo, 
                formatDate(marcaje.dFecha),
                formatTime(marcaje.HoraIni),
                formatTime(marcaje.HoraFin),
                formatTime(marcaje.sDuracion)
            ]);

            doc.autoTable({
                head: tableHeaders,
                body: tableData,
                startY: tableConfig.start_y,
                headStyles: {
                    fillColor: hexToRgb(tableConfig.styles.header.background),
                    textColor: hexToRgb(tableConfig.styles.header.text_color),
                    fontSize: tableConfig.styles.header.font_size,
                    fontStyle: tableConfig.styles.header.font_weight,
                    font: tableConfig.styles.font
                },
                columnStyles: {
                    0: { cellWidth: 40 }, 
                    1: { cellWidth: tableConfig.columns.fecha.width },
                    2: { cellWidth: tableConfig.columns.hora_inicio.width },
                    3: { cellWidth: tableConfig.columns.hora_fin.width },
                    4: { cellWidth: tableConfig.columns.duracion.width }
                },
                alternateRowStyles: {
                    fillColor: hexToRgb(tableConfig.styles.alternating_rows.background)
                },
                margin: {
                    left: pdfConfig.page.margins.left,
                    right: pdfConfig.page.margins.right
                },
                styles: {
                    font: tableConfig.styles.font,
                    fontSize: tableConfig.styles.font_size,
                    cellPadding: tableConfig.styles.cell_padding,
                    overflow: 'linebreak'
                }
            });

            const finalY = doc.previousAutoTable.finalY || tableConfig.start_y;
            const summaryConfig = pdfConfig.summary;
            doc.setFontSize(summaryConfig.style.font_size);
            doc.setFont(summaryConfig.style.font, summaryConfig.style.font_weight);
            
            doc.text(
                summaryConfig.total_label.text,
                summaryConfig.total_label.position.x,
                finalY + 15
            );
            doc.text(
                totalDuration,
                summaryConfig.total_value.position.x,
                finalY + 15
            );

            return doc;
        } catch (err) {
            setError(err.message || 'Error al generar el PDF');
            return null;
        }
    };

    const handlePreview = async () => {
        setLoading(true);
        setError('');
        const doc = await generatePDFDocument();
        if (doc) {
            const pdfBlob = doc.output('bloburl');
            setPdfUrl(pdfBlob);
            setShowPreview(true);
        }
        setLoading(false);
    };

    const handleDownload = async () => {
        setLoading(true);
        setError('');
        const doc = await generatePDFDocument();
        if (doc) {
            const fechaActual = new Date().toISOString().split('T')[0];
            doc.save(`Marcajes_${fechaActual}.pdf`);
        }
        setLoading(false);
    };

    const handleClosePreview = () => {
        setShowPreview(false);
        if (pdfUrl) {
            URL.revokeObjectURL(pdfUrl);
            setPdfUrl(null);
        }
    };

    const selectedCount = Object.values(selectedMarcajes).filter(Boolean).length;

    return (
        <div className="pdf-generator-container">
            <div className="pdf-generator-buttons">
                <button
                    onClick={handlePreview}
                    disabled={loading || selectedCount === 0}
                    className={`pdf-generator-button preview ${loading || selectedCount === 0 ? 'disabled' : ''}`}
                >
                    Vista Previa ({selectedCount} seleccionados)
                </button>
                <button
                    onClick={handleDownload}
                    disabled={loading || selectedCount === 0}
                    className={`pdf-generator-button download ${loading || selectedCount === 0 ? 'disabled' : ''}`}
                >
                    Descargar PDF
                </button>
            </div>

            {error && (
                <div className="pdf-error-message">
                    <span className="pdf-error-icon">!</span>
                    <p>{error}</p>
                </div>
            )}

            {showPreview && (
                <div className="pdf-preview-modal">
                    <div className="pdf-preview-content">
                        <div className="pdf-preview-header">
                            <h2>Vista Previa del PDF</h2>
                            <button onClick={handleClosePreview} className="pdf-preview-close">×</button>
                        </div>
                        <iframe src={pdfUrl} title="Vista previa del PDF" />
                        <div className="pdf-preview-footer">
                            <button onClick={handleDownload} className="pdf-generator-button download">
                                Descargar PDF
                            </button>
                            <button onClick={handleClosePreview} className="pdf-generator-button cancel">
                                Cerrar
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default PDFGenerator;