import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import '../styles/module-unlicensed.css';

const ModuleUnlicensed = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const moduleName = location.state?.moduleName || "Este módulo";

    const moduleNames = {
        'MANAGEMENT': 'Gestión de Empleados',
        'PRESENCE': 'Gestión de Presencia',
        'MATERIALS': 'Control de Materiales',
    };

    const displayName = moduleNames[moduleName] || moduleName;

    return (
        <div className="module-unlicensed">
            <h1>Módulo no disponible</h1>
            <p>El módulo <strong>{displayName}</strong> no está incluido en su plan actual.</p>
            <p>Contacte con su administrador para activar este módulo.</p>

            <div className="action-buttons">
                <button onClick={() => navigate(-1)} className="back-button">
                    Volver al inicio
                </button>
                <a href="mailto:soporte@infoprol.es" className="contact-button">
                    Solicitar información
                </a>
            </div>
        </div>
    );
};

export default ModuleUnlicensed;