import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import { format } from 'date-fns';
import { FileSpreadsheet, AlertCircle } from 'lucide-react';

const ExcelMarcajesGenerator = ({
    selectedMarcajes,
    currentMarcajes,
    showClientInfo,
    ignoreDateFilter,
    startDate,
    endDate,
    totalDuration
}) => {
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    
    const generateExcel = async (isPreview = false) => {
        try {
            setLoading(true);
            setError('');

            const empresaId = localStorage.getItem('empresaId');
            if (!empresaId) {
                throw new Error('No se encontró el ID de la empresa');
            }

            const selectedRows = currentMarcajes.filter(
                marcaje => selectedMarcajes[marcaje.idMarcaje]
            );

            if (selectedRows.length === 0) {
                throw new Error("Por favor, seleccione al menos una fila para generar el Excel");
            }

            const wb = XLSX.utils.book_new();
            
            const excelData = selectedRows.map(marcaje => ({
                'Operario': marcaje.sNombre_Completo,
                'Duración': marcaje.sDuracion || '-',
                'Hora Inicio': marcaje.HoraIni,
                'Hora Fin': marcaje.HoraFin || '-',
                'Fecha': format(new Date(marcaje.dFecha), 'dd/MM/yyyy'),
            }));

            const periodoInfo = [{
                'Período': ignoreDateFilter 
                    ? "Todas las fechas" 
                    : `${format(startDate, 'dd/MM/yyyy')} - ${format(endDate, 'dd/MM/yyyy')}`,
                'Total Horas': totalDuration
            }];

            const wsData = XLSX.utils.json_to_sheet(excelData);
            const wsInfo = XLSX.utils.json_to_sheet(periodoInfo);

            const wscols = [
                {wch: 15}, 
                {wch: 15}, 
                {wch: 15}, 
                {wch: 15}, 
                {wch: 30}  
            ];
            wsData['!cols'] = wscols;

            XLSX.utils.book_append_sheet(wb, wsData, "Marcajes");
            XLSX.utils.book_append_sheet(wb, wsInfo, "Información");

            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const blob = new Blob([excelBuffer], { 
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' 
            });

            if (!isPreview) {
                const fileName = `Marcajes_${format(new Date(), 'yyyyMMdd_HHmm')}.xlsx`;
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            }

            return blob;
        } catch (err) {
            setError(err.message || 'Error al generar el Excel');
            return null;
        } finally {
            setLoading(false);
        }
    };

    const selectedCount = Object.values(selectedMarcajes).filter(Boolean).length;

    return (
        <div className="flex flex-col gap-4">
            <div className="flex gap-2">
                <button
                    onClick={() => generateExcel(false)}
                    disabled={loading || selectedCount === 0}
                    className="flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                    <FileSpreadsheet size={20} />
                    <span>Descargar Excel ({selectedCount} seleccionados)</span>
                </button>
            </div>

            {error && (
                <div className="flex items-start gap-2 p-4 bg-red-50 border border-red-200 rounded">
                    <AlertCircle className="text-red-500" size={20} />
                    <div className="flex flex-col">
                        <h3 className="font-semibold text-red-700">Error</h3>
                        <p className="text-red-600">{error}</p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ExcelMarcajesGenerator;