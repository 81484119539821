import React, { useState, useEffect, useCallback } from 'react';
import { Clock, AlertCircle, Coffee, Briefcase } from 'lucide-react';
import axios from 'axios';
import config from '../config';
import '../styles/timetrack.css';
import '../styles/theme.css';

const TimeTracker = ({ selectedClientId, onError }) => {
  const [isRunning, setIsRunning] = useState(false);
  const [isIncidenceRunning, setIsIncidenceRunning] = useState(false);
  const [marcajeId, setMarcajeId] = useState(null);
  const [tiposIncidencia, setTiposIncidencia] = useState([]);
  const [incidenciasActivas, setIncidenciasActivas] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [procesando, setProcesando] = useState(false);

  const userId = localStorage.getItem('userId');
  const environment = process.env.NODE_ENV || 'production';
  const apiUrl = config[environment].API_BASE_URL;

  const checkMarcajeStatus = useCallback(async () => {
    if (!userId) return;

    try {
      const response = await axios.get(`${apiUrl}check_marcaje.php?userId=${userId}`);

      if (response.data.success) {
        const newIsRunning = !response.data.isClosed;
        setIsRunning(newIsRunning);

        if (newIsRunning) {
          setMarcajeId(response.data.marcajeId);
          localStorage.setItem('marcajeId', response.data.marcajeId);
          const activeIncidencias = response.data.incidenciasActivas || [];
          setIncidenciasActivas(activeIncidencias);
          setIsIncidenceRunning(activeIncidencias.length > 0);
        } else {
          setMarcajeId(null);
          localStorage.removeItem('marcajeId');
          setIncidenciasActivas([]);
          setIsIncidenceRunning(false);
        }
      }
      return response.data;
    } catch (error) {
      throw new Error('Error al verificar el estado del marcaje');
    }
  }, [apiUrl, userId]);

  const fetchTiposIncidencia = useCallback(async () => {
    if (!userId) return;

    try {
      const response = await axios.get(`${apiUrl}get_tipos_incidencia.php?userId=${userId}`);

      if (response.data.success && Array.isArray(response.data.data)) {
        setTiposIncidencia(response.data.data);
      } else {
        throw new Error('Error al obtener los tipos de incidencia');
      }
    } catch (error) {
      throw new Error('Error al cargar los tipos de incidencia');
    }
  }, [apiUrl, userId]);

  const handleStartChrono = async () => {
    if (procesando) return;

    try {
      setProcesando(true);
      const payload = {
        idUsuario: parseInt(userId),
        idCliente: parseInt(selectedClientId),
        HoraIni: new Date().toLocaleTimeString('es-ES', {
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: false
        }),
        dFecha: new Date().toLocaleDateString('es-ES', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric'
        }),
        isIncidencia: false
      };

      const response = await axios.post(`${apiUrl}start_marcaje.php`, payload);

      if (response.data.success) {
        setIsRunning(true);
        setMarcajeId(response.data.marcajeId);
        localStorage.setItem('marcajeId', response.data.marcajeId);
      } else {
        throw new Error(response.data.error || 'Error al iniciar el marcaje');
      }

      await checkMarcajeStatus();
    } catch (error) {
      const errorMsg = error.message || 'Error al procesar la operación';
      setError(errorMsg);
      onError?.(errorMsg);
    } finally {
      setProcesando(false);
    }
  };

  const handleStopChrono = async (cerrarTodo = true) => {
    if (procesando) return;

    try {
      setProcesando(true);
      const payload = {
        idMarcaje: parseInt(marcajeId),
        HoraFin: new Date().toLocaleTimeString('es-ES', {
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: false
        }),
        cerrarTodo
      };


      const response = await axios.post(`${apiUrl}stop_marcaje.php?userId=${userId}`, payload);


      if (response.data && !response.data.error) {
        if (cerrarTodo) {
          setIsRunning(false);
          setMarcajeId(null);
          setIncidenciasActivas([]);
          setIsIncidenceRunning(false);
          localStorage.removeItem('marcajeId');
        }
        await checkMarcajeStatus();
      } else {
        throw new Error(response.data.error || 'Error al detener el marcaje');
      }
    } catch (error) {
      const errorMsg = error.message || 'Error al procesar la operación';
      console.error('Error detallado:', error);
      setError(errorMsg);
      onError?.(errorMsg);
    } finally {
      setProcesando(false);
    }
  };

  const handleStopIncidencia = async (incidenciaId) => {
    if (procesando) return;

    try {
      setProcesando(true);
      const payload = {
        idMarcaje: incidenciaId,
        HoraFin: new Date().toLocaleTimeString('es-ES', {
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: false
        }),
        cerrarTodo: false
      };

      const response = await axios.post(`${apiUrl}stop_marcaje.php?userId=${userId}`, payload);

      if (response.data.success) {
        setIsIncidenceRunning(false);
        await checkMarcajeStatus();
      } else {
        throw new Error(response.data.error || 'Error al detener la incidencia');
      }
    } catch (error) {
      const errorMsg = error.message || 'Error al procesar la operación';
      setError(errorMsg);
      onError?.(errorMsg);
    } finally {
      setProcesando(false);
    }
  };

  const handleIncidencia = async (tipo) => {
    if (!userId || procesando || !isRunning || isIncidenceRunning) return;

    try {
      setProcesando(true);
      const payload = {
        idUsuario: parseInt(userId),
        idCliente: parseInt(selectedClientId),
        HoraIni: new Date().toLocaleTimeString('es-ES', {
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: false
        }),
        dFecha: new Date().toLocaleDateString('es-ES', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric'
        }),
        idTipoIncidencia: tipo.idTipoIncidencia,
        isIncidencia: true
      };

      const response = await axios.post(`${apiUrl}start_marcaje.php`, payload);

      if (!response.data.success) {
        throw new Error(response.data.error || 'Error al registrar la incidencia');
      }

      setIsIncidenceRunning(true);
      await checkMarcajeStatus();
    } catch (error) {
      const errorMsg = error.message || 'Error al procesar la operación';
      setError(errorMsg);
      onError?.(errorMsg);
    } finally {
      setProcesando(false);
    }
  };

  const getIconForTipo = (tipo) => {
    const icons = {
      'Jornada Laboral': Briefcase,
      'Pausa Almuerzo': Coffee,
      'Incidencia Técnica': AlertCircle
    };
    const IconComponent = icons[tipo] || Clock;
    return <IconComponent className="incidencia-icon" />;
  };

  useEffect(() => {
    const initializeComponent = async () => {
      try {
        setLoading(true);
        await Promise.all([
          checkMarcajeStatus(),
          fetchTiposIncidencia()
        ]);
      } catch (error) {
        const errorMsg = error.message || 'Error al inicializar el componente';
        setError(errorMsg);
        onError?.(errorMsg);
      } finally {
        setLoading(false);
      }
    };

    initializeComponent();
  }, [checkMarcajeStatus, fetchTiposIncidencia, onError]);

  if (loading) {
    return <div className="tracker-loading">Cargando...</div>;
  }

  return (
      <div className="tracker-container">
        {error && (
            <div className="tracker-error">
              <p>{error}</p>
              <button
                  onClick={() => setError(null)}
                  className="error-close-button"
                  aria-label="Cerrar error"
              >
                ×
              </button>
            </div>
        )}

        <div className="chrono-section">
          <button
              className={`chrono-button ${isRunning ? 'stop-button' : 'start-button'}`}
              onClick={isRunning ? () => handleStopChrono(true) : handleStartChrono}
              disabled={procesando}
              aria-label={isRunning ? 'Detener marcaje' : 'Iniciar marcaje'}
          >
            {isRunning ? (
                <svg viewBox="0 0 24 24" fill="currentColor" width="128" height="128">
                  <rect x="6" y="6" width="12" height="12" />
                </svg>
            ) : (
                <svg viewBox="0 0 24 24" fill="currentColor" width="128" height="128">
                  <path d="M8 5v14l11-7z" />
                </svg>
            )}
          </button>
        </div>

        <div className="incidencias-section">
          <h2 className="incidencias-title">Registro de Incidencias</h2>

          {!isRunning && (
              <div className="incidencias-warning">
                <p>No hay un marcaje iniciado. Inicie un marcaje para registrar incidencias.</p>
              </div>
          )}

          {isIncidenceRunning && (
              <div className="incidencias-warning incidencias-active-warning">
                <p>Ya hay una incidencia activa. Finalice la incidencia actual antes de iniciar otra.</p>
              </div>
          )}

          {isRunning && incidenciasActivas.length > 0 && (
              <div className="incidencias-activas">
                <h3 className="incidencias-subtitle">Incidencias Activas</h3>
                <div className="incidencias-activas-grid">
                  {incidenciasActivas.map((incidencia) => (
                      <div key={incidencia.idMarcaje} className="incidencia-activa-card">
                        {getIconForTipo(incidencia.sTipoIncidencia)}
                        <div className="incidencia-card-content">
                          <h4 className="incidencia-card-title">{incidencia.sTipoIncidencia}</h4>
                          <p className="incidencia-time">Inicio: {incidencia.HoraIni}</p>
                        </div>
                        <button
                            onClick={() => handleStopIncidencia(incidencia.idMarcaje)}
                            className="incidencia-stop-button"
                            disabled={procesando}
                        >
                          Finalizar
                        </button>
                      </div>
                  ))}
                </div>
              </div>
          )}

          <div className="incidencias-grid">
            {tiposIncidencia.map((tipo) => {
              const tipoActivo = incidenciasActivas.some(
                  inc => inc.idTipoIncidencia === tipo.idTipoIncidencia
              );

              return (
                  <button
                      key={tipo.idTipoIncidencia}
                      onClick={() => handleIncidencia(tipo)}
                      className={`incidencia-card ${(tipoActivo || isIncidenceRunning) ? 'incidencia-disabled' : ''}`}
                      disabled={procesando || !isRunning || tipoActivo || isIncidenceRunning}
                  >
                    {getIconForTipo(tipo.sTipoIncidencia)}
                    <div className="incidencia-card-content">
                      <h3 className="incidencia-card-title">{tipo.sTipoIncidencia}</h3>
                      <p className="incidencia-card-description">{tipo.sDescripcion}</p>
                      {tipoActivo && (
                          <span className="incidencia-activa-badge">En curso</span>
                      )}
                    </div>
                  </button>
              );
            })}
          </div>
        </div>
      </div>
  );
};

export default TimeTracker;