import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Search, Package2 } from 'lucide-react';
import config from '../config';
import ModalRequest from './ModalRequest';
import '../styles/requestslist.css';
import GoBack from './GoBack';

const RequestsList = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedPaquete, setSelectedPaquete] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterStatus, setFilterStatus] = useState('all');
  const [refreshData, setRefreshData] = useState(false);

  const environment = process.env.NODE_ENV || 'development';

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userId = localStorage.getItem('userId');
        if (!userId) {
          throw new Error('No se encontró el ID de usuario');
        }

        try {
          await axios.get(
              `${config[environment].API_BASE_URL}update_packages.php`,
              { params: { userId } }
          );
        } catch (updateError) {
          console.warn('Error al actualizar paquetes:', updateError);
        }

        const response = await axios.get(
            `${config[environment].API_BASE_URL}get_packages_movements.php`,
            { params: { userId } }
        );

        const paquetesAgrupados = {};
        response.data.data.forEach(item => {
          if (!paquetesAgrupados[item.idPaquete]) {
            paquetesAgrupados[item.idPaquete] = {
              ...item,
              movimientos: []
            };
          }
          if (item.idMovimiento) {
            paquetesAgrupados[item.idPaquete].movimientos.push({
              idMovimiento: item.idMovimiento,
              idArticulo: item.idArticulo,
              iUnidades: item.iUnidades,
              idEstado: item.idEstado,
              bActivo: item.movimiento_activo
            });
          }
        });

        setData(Object.values(paquetesAgrupados));
        setLoading(false);
      } catch (err) {
        setError(err.message || 'Error al cargar los datos');
        setLoading(false);
      }
    };

    fetchData();
  }, [environment, refreshData]);

  const handleRowClick = (paquete) => {
    setSelectedPaquete(paquete);
    setIsModalOpen(true);
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleString('es-ES', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const handleStateChange = () => {
    setRefreshData(prev => !prev);
  };

  const filteredData = data.filter(paquete => {
    const matchesSearch =
        paquete.idPaquete.toString().includes(searchTerm) ||
        paquete.idCliente.toString().includes(searchTerm) ||
        formatDate(paquete.dFecha).toLowerCase().includes(searchTerm.toLowerCase());

    const matchesFilter =
        filterStatus === 'all' ||
        (filterStatus === 'pending' && paquete.bCompletado !== '1') ||
        (filterStatus === 'completed' && paquete.bCompletado === '1');

    return matchesSearch && matchesFilter;
  });

  if (loading) {
    return <div className="loading">Cargando...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
      <div className="requests-container">
        <GoBack />
        <div className="requests-list">
          <div className="header-requests">
            <h1 className="title">Pedidos y Movimientos</h1>

            <div className="controls">
              <div className="search-wrapper">
                <input
                    type="text"
                    placeholder="Buscar paquetes..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="search-input"
                />
                <Search className="search-icon" size={18} />
              </div>

              <select
                  value={filterStatus}
                  onChange={(e) => setFilterStatus(e.target.value)}
                  className="filter-select"
              >
                <option value="all">Todos los estados</option>
                <option value="completed">Completados</option>
                <option value="pending">Pendientes</option>
              </select>
            </div>
          </div>

          <div className="table-container">
            <table className="requests-table">
              <thead>
              <tr>
                <th>ID Paquete</th>
                <th>Cliente</th>
                <th>Fecha</th>
                <th>Estado</th>
                <th>Movimientos</th>
              </tr>
              </thead>
              <tbody>
              {filteredData.map((paquete) => (
                  <tr
                      key={paquete.idPaquete}
                      onClick={() => handleRowClick(paquete)}
                      className="table-row"
                  >
                    <td>
                      <div className="package-id">
                        <Package2 className="package-icon" size={18} />
                        #{paquete.idPaquete}
                      </div>
                    </td>
                    <td>{paquete.idCliente}</td>
                    <td>{formatDate(paquete.dFecha)}</td>
                    <td>
                    <span className={`status-badge ${paquete.bCompletado === '1' ? 'status-completed' : 'status-pending'}`}>
                      {paquete.bCompletado === '1' ? 'Completado' : 'Pendiente'}
                    </span>
                    </td>
                    <td>
                    <span className="movement-count">
                      {paquete.movimientos.length} movimientos
                    </span>
                    </td>
                  </tr>
              ))}
              </tbody>
            </table>
          </div>

          {isModalOpen && selectedPaquete && (
              <ModalRequest
                  paquete={selectedPaquete}
                  onClose={() => {
                    setIsModalOpen(false);
                    setSelectedPaquete(null);
                  }}
                  onUpdate={(updatedPaquete) => {
                    const newData = data.map(p =>
                        p.idPaquete === updatedPaquete.idPaquete ? updatedPaquete : p
                    );
                    setData(newData);
                  }}
                  onStateChange={handleStateChange}
              />
          )}
        </div>
      </div>
  );
};

export default RequestsList;