import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Calendar, Clock, User, Users, Briefcase, AlertCircle, Plus } from 'lucide-react';
import config from '../config';
import '../styles/schedule.css';
import ModalGroup from "./ModalGroup";
import ModalEvent from "./ModalEvent";

const CalendarioPage = ({ userId }) => {
    const [eventos, setEventos] = useState([]);
    const [usuarios, setUsuarios] = useState([]);
    const [clientes, setClientes] = useState([]);
    const [grupos, setGrupos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isGroupModalOpen, setIsGroupModalOpen] = useState(false);
    const [isEventModalOpen, setIsEventModalOpen] = useState(false);
    const [refreshGroups, setRefreshGroups] = useState(false);
    const [refreshEvents, setRefreshEvents] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);

    const [fechaInicio, setFechaInicio] = useState(new Date().toISOString().split('T')[0]);
    const [fechaFin, setFechaFin] = useState(
        new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString().split('T')[0]
    );
    const [clienteSeleccionado, setClienteSeleccionado] = useState('');
    const [usuarioSeleccionado, setUsuarioSeleccionado] = useState('');
    const [grupoSeleccionado, setGrupoSeleccionado] = useState('');

    const environment = process.env.NODE_ENV || 'development';
    const apiBaseUrl = config[environment].API_BASE_URL;

    const handleOpenGroupModal = () => {
        setIsGroupModalOpen(true);
    };

    const handleCloseGroupModal = () => {
        setIsGroupModalOpen(false);
    };

    const handleOpenNewEventModal = () => {
        setSelectedEvent(null);
        setIsEventModalOpen(true);
    };

    const handleOpenEditEventModal = (evento) => {
        setSelectedEvent(evento);
        setIsEventModalOpen(true);
    };

    const handleCloseEventModal = () => {
        setIsEventModalOpen(false);
        setSelectedEvent(null);
    };

    const handleGroupsChange = () => {
        setRefreshGroups(!refreshGroups);
    };

    const handleEventsChange = () => {
        setRefreshEvents(!refreshEvents);
    };

    const formatearHora = (hora) => {
        if (!hora) return '';
        return hora.substring(0, 5);
    };

    const formatearFecha = (fecha) => {
        if (!fecha) return '';
        const opciones = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(fecha).toLocaleDateString('es-ES', opciones);
    };

    useEffect(() => {
        const obtenerUsuarios = async () => {
            const userId = localStorage.getItem('userId');
            try {
                const response = await axios.get(
                    `${config[environment].PHP_GET_USERS_URL}?userId=${userId}`
                );
                setUsuarios(response.data);
            } catch (error) {
                console.error('Error al cargar usuarios:', error);
                setError('Error al cargar los usuarios');
            }
        };

        obtenerUsuarios();
    }, [apiBaseUrl, environment, userId]);

    useEffect(() => {
        const obtenerClientes = async () => {
            try {
                const response = await axios.get(
                    `${config[environment].PHP_GET_CLIENTS_A_URL}`, {
                        params: {
                            userId: localStorage.getItem('userId')
                        }
                    }
                );
                setClientes(response.data);
            } catch (error) {
                console.error('Error al cargar clientes:', error);
                setError('Error al cargar los clientes');
            }
        };

        obtenerClientes();
    }, [apiBaseUrl, environment]);

    useEffect(() => {
        const obtenerGrupos = async () => {
            const userId = localStorage.getItem('userId');
            try {
                const response = await axios.get(
                    `${config[environment].PHP_GET_GROUPS_URL}?userId=${userId}`
                );
                setGrupos(response.data);
            } catch (error) {
                console.error('Error al cargar grupos:', error);
                setError('Error al cargar los grupos');
            }
        };

        obtenerGrupos();
    }, [apiBaseUrl, environment, userId, refreshGroups]);

    useEffect(() => {
        const obtenerEventos = async () => {
            const userId = localStorage.getItem('userId');
            setLoading(true);
            try {
                let url = `${config[environment].PHP_GET_CALENDAR_EVENTS_URL}?userId=${userId}&fechaInicio=${fechaInicio}&fechaFin=${fechaFin}`;

                if (clienteSeleccionado) {
                    url += `&idCliente=${clienteSeleccionado}`;
                }

                if (usuarioSeleccionado) {
                    url += `&idUsuario=${usuarioSeleccionado}`;
                }

                if (grupoSeleccionado) {
                    url += `&idGrupo=${grupoSeleccionado}`;
                }

                const response = await axios.get(url);
                setEventos(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Error al cargar eventos:', error);
                setError('Error al cargar los eventos');
                setLoading(false);
            }
        };

        obtenerEventos();
    }, [apiBaseUrl, environment, userId, fechaInicio, fechaFin, clienteSeleccionado, usuarioSeleccionado, grupoSeleccionado, refreshEvents]);

    const handleEventoClick = (evento) => {
        handleOpenEditEventModal(evento);
    };

    return (
        <div className="calendario-lista-container">
            <div className="pagina-acciones">
                <button className="btn-crear-evento" onClick={handleOpenNewEventModal}>
                    <Plus size={18} />
                    Crear Evento
                </button>
                <button className="btn-administrar-grupos" onClick={handleOpenGroupModal}>
                    <Users size={18} />
                    Administrar Grupos
                </button>
            </div>
            <h1 className="calendario-titulo">Calendario de Eventos</h1>

            <div className="filtros-container">
                <div className="filtro-grupo">
                    <label htmlFor="fechaInicio">Desde:</label>
                    <input
                        type="date"
                        id="fechaInicio"
                        value={fechaInicio}
                        onChange={(e) => setFechaInicio(e.target.value)}
                        className="filtro-input"
                    />
                </div>

                <div className="filtro-grupo">
                    <label htmlFor="fechaFin">Hasta:</label>
                    <input
                        type="date"
                        id="fechaFin"
                        value={fechaFin}
                        onChange={(e) => setFechaFin(e.target.value)}
                        className="filtro-input"
                    />
                </div>

                <div className="filtro-grupo">
                    <label htmlFor="cliente">Cliente:</label>
                    <select
                        id="cliente"
                        value={clienteSeleccionado}
                        onChange={(e) => setClienteSeleccionado(e.target.value)}
                        className="filtro-select"
                    >
                        <option value="">Todos los clientes</option>
                        {clientes.map((cliente) => (
                            <option key={cliente.idCliente} value={cliente.idCliente}>
                                {cliente.sCliente}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="filtro-grupo">
                    <label htmlFor="usuario">Operario:</label>
                    <select
                        id="usuario"
                        value={usuarioSeleccionado}
                        onChange={(e) => setUsuarioSeleccionado(e.target.value)}
                        className="filtro-select"
                    >
                        <option value="">Todos los operarios</option>
                        {usuarios.map((usuario) => (
                            <option key={usuario.id} value={usuario.id}>
                                {usuario.sNombreCompleto}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="filtro-grupo">
                    <label htmlFor="grupo">Grupo:</label>
                    <select
                        id="grupo"
                        value={grupoSeleccionado}
                        onChange={(e) => setGrupoSeleccionado(e.target.value)}
                        className="filtro-select"
                    >
                        <option value="">Todos los grupos</option>
                        {grupos.map((grupo) => (
                            <option key={grupo.id} value={grupo.id}>
                                Grupo {grupo.id} ({grupo.numUsuarios} operarios)
                            </option>
                        ))}
                    </select>
                </div>
            </div>

            {loading ? (
                <div className="loading-message">Cargando eventos...</div>
            ) : error ? (
                <div className="error-message">{error}</div>
            ) : eventos.length === 0 ? (
                <div className="no-events-message">
                    <AlertCircle size={24} />
                    <p>No se encontraron eventos para los filtros seleccionados.</p>
                </div>
            ) : (
                <div className="eventos-lista">
                    {eventos.map((evento) => (
                        <div
                            key={evento.id}
                            className="evento-item"
                            onClick={() => handleEventoClick(evento)}
                        >
                            <div className="evento-fecha">
                                <Calendar size={18} />
                                <span>{formatearFecha(evento.fecha)}</span>
                            </div>

                            <div className="evento-contenido">
                                <h3 className="evento-titulo">{evento.titulo}</h3>

                                <div className="evento-detalles">
                                    <div className="evento-detalle">
                                        <Clock size={16} />
                                        <span>
                                            {formatearHora(evento.horaInicio)} - {formatearHora(evento.horaFin)}
                                        </span>
                                    </div>

                                    <div className="evento-detalle">
                                        <Briefcase size={16} />
                                        <span>{evento.cliente}</span>
                                    </div>

                                    {evento.esGrupo ? (
                                        <div className="evento-detalle">
                                            <Users size={16} />
                                            <span>Grupo {evento.idGrupo}</span>
                                        </div>
                                    ) : (
                                        <div className="evento-detalle">
                                            <User size={16} />
                                            <span>{evento.operario}</span>
                                        </div>
                                    )}
                                </div>

                                {evento.nota && (
                                    <p className="evento-nota">{evento.nota}</p>
                                )}

                                <div className="evento-tags">
                                    {evento.esSerie && (
                                        <span className="evento-tag evento-recurrente">Recurrente</span>
                                    )}
                                    {evento.esGrupo && (
                                        <span className="evento-tag evento-grupo">Grupo</span>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}

            {/* Modal para gestionar grupos */}
            <ModalGroup
                userId={userId}
                isOpen={isGroupModalOpen}
                onClose={handleCloseGroupModal}
                onGroupsChange={handleGroupsChange}
            />

            {/* Modal para crear/editar eventos */}
            <ModalEvent
                userId={userId}
                isOpen={isEventModalOpen}
                onClose={handleCloseEventModal}
                onEventsChange={handleEventsChange}
                event={selectedEvent}
            />
        </div>
    );
};

export default CalendarioPage;