import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../config';
import GoBack from "../components/GoBack";
import "../styles/worklog.css";
import "../styles/buttons.css";
import ClientChoosing from "../components/ClientChoosing";
import Client from "../components/Client";
import TimeTracker from '../components/TimeTracker';

const WorkLog = () => {
    const [clientName, setClientName] = useState('Cliente');
    const [selectedClientId, setSelectedClientId] = useState(localStorage.getItem('selectedClientId') || '');
    const [error, setError] = useState('');

    const environment = process.env.NODE_ENV || 'production';
    const apiUrl = config[environment].API_BASE_URL;

    useEffect(() => {
        const fetchClientName = async () => {
            const userId = localStorage.getItem('userId');
            if (!userId) {
                setError('No se encontró el ID de usuario');
                return;
            }

            if (!selectedClientId) {
                setClientName('Cliente');
                return;
            }

            try {
                const response = await axios.get(`${apiUrl}get_client_w.php`, {
                    params: {
                        userId: userId,
                        selectedClientId: selectedClientId
                    }
                });

                if (response.data.error) {
                    setError(response.data.error);
                    return;
                }

                setClientName(response.data.sCliente);
                setError('');
            } catch (error) {
                setError('Error al obtener el nombre del cliente');
                console.error('Error:', error);
            }
        };

        fetchClientName();
    }, [selectedClientId, apiUrl]);

    const handleClientChange = (newClientId) => {
        setSelectedClientId(newClientId);
        localStorage.setItem('selectedClientId', newClientId);
    };

    const handleError = (errorMessage) => {
        setError(errorMessage);
    };

    return (
        <div className="worklog">
            <GoBack />
            {error && <div className="error-message">{error}</div>}
            <Client clientName={clientName} />
            <ClientChoosing onClientChange={handleClientChange} />
            {selectedClientId ? (
                <TimeTracker 
                    selectedClientId={selectedClientId}
                    onError={handleError}
                />
            ) : (
                <div className="client-warning">
                    Seleccione un cliente para comenzar
                </div>
            )}
        </div>
    );
};

export default WorkLog;