import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { MapPin, X } from 'lucide-react';
import { Circles } from 'react-loader-spinner';
import config from '../config';
import '../styles/locations.css';

const AddLocation = ({ isOpen, onClose, clientId, clientName, onLocationAdded }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [formData, setFormData] = useState({
        nombreUbicacion: '',
        coordenadas: ''
    });
    const [clients, setClients] = useState([]);
    const [selectedClientId, setSelectedClientId] = useState(clientId || '');

    useEffect(() => {
        if (isOpen) {
            setFormData({
                nombreUbicacion: '',
                coordenadas: ''
            });
            setSelectedClientId(clientId || '');
            setError(null);

            if (!clientId) {
                fetchClients();
            }
        }
    }, [isOpen, clientId]);

    const fetchClients = async () => {
        try {
            setLoading(true);
            const environment = process.env.NODE_ENV || 'development';
            const userId = localStorage.getItem('userId');
            const response = await axios.get(`${config[environment].API_BASE_URL}get_clients_a.php`, {
                params: { userId }
            });

            if (Array.isArray(response.data)) {
                setClients(response.data);
            } else {
                setClients([]);
            }
        } catch (err) {
            console.error('Error fetching clients:', err);
            setError('Error al cargar los clientes');
        } finally {
            setLoading(false);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleClientChange = (e) => {
        setSelectedClientId(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!formData.nombreUbicacion.trim()) {
            setError('El nombre de la ubicación es obligatorio');
            return;
        }

        const clientToUse = selectedClientId || clientId;
        if (!clientToUse) {
            setError('Debe seleccionar un cliente');
            return;
        }

        try {
            setLoading(true);
            setError(null);

            const environment = process.env.NODE_ENV || 'development';
            const userId = localStorage.getItem('userId');

            const url = `${config[environment].API_BASE_URL}add_location.php`;
            const params = {
                userId,
                idCliente: clientToUse,
                sNombreUbicacion: formData.nombreUbicacion,
                sCoordenadas: formData.coordenadas
            };

            const response = await axios.get(url, { params });

            if (response.data && response.data.success) {
                if (onLocationAdded) {
                    onLocationAdded();
                }
                onClose();
            } else {
                setError(response.data.message || 'Error al añadir la ubicación');
            }
        } catch (err) {
            console.error('Error adding location:', err);
            setError('Error al añadir la ubicación. Por favor, inténtelo de nuevo más tarde.');
        } finally {
            setLoading(false);
        }
    };

    if (!isOpen) return null;

    return (
        <div className="locations-modal-overlay">
            <div className="locations-modal-content">
                <button className="locations-modal-close" onClick={onClose}><X size={24} /></button>
                <div className="locations-modal-header">
                    <MapPin size={24} />
                    <h2>Añadir Nueva Ubicación{clientName ? ` para ${clientName}` : 'Cliente'}</h2>
                </div>

                {loading && <div style={{ width: "100%", display: "flex", justifyContent: "center", margin: "20px 0" }}>
                    <Circles
                        height="80"
                        width="80"
                        color="#000000"
                        ariaLabel="circles-loading"
                        visible={true}
                    />
                </div>}

                {error && <p className="locations-error">{error}</p>}

                <form onSubmit={handleSubmit} className="location-form">
                    {!clientId && (
                        <div className="form-group">
                            <label htmlFor="client">Cliente:</label>
                            <select
                                id="client"
                                value={selectedClientId}
                                onChange={handleClientChange}
                                disabled={loading || !!clientId}
                                required
                            >
                                <option value="">Seleccione un cliente</option>
                                {clients.map(client => (
                                    <option key={client.idCliente} value={client.idCliente}>
                                        {client.sCliente}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )}

                    <div className="form-group">
                        <label htmlFor="nombreUbicacion">Nombre de la Ubicación:</label>
                        <input
                            type="text"
                            id="nombreUbicacion"
                            name="nombreUbicacion"
                            value={formData.nombreUbicacion}
                            onChange={handleChange}
                            disabled={loading}
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="coordenadas">Coordenadas:</label>
                        <input
                            type="text"
                            id="coordenadas"
                            name="coordenadas"
                            value={formData.coordenadas}
                            onChange={handleChange}
                            disabled={loading}
                            placeholder="Ej: 41.40338, 2.17403"
                        />
                    </div>

                    <div className="form-actions">
                        <button
                            type="button"
                            onClick={onClose}
                            className="cancel-button"
                            disabled={loading}
                        >
                            Cancelar
                        </button>
                        <button
                            type="submit"
                            className="submit-button"
                            disabled={loading}
                        >
                            Guardar
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddLocation;