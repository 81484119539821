import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../config';
import "../styles/buttons.css";
import Modal from './ModalChooseClient';
import { useTranslation } from '../TranslationContext';

const DropDown = ({ onClientChange }) => {
    const { t } = useTranslation();
    const [clients, setClients] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState('');
    const [clientMap, setClientMap] = useState({});

    const environment = process.env.NODE_ENV || 'production';
    const apiUrl = config[environment].API_BASE_URL;

    useEffect(() => {
        const fetchClients = async () => {
            const userId = localStorage.getItem('userId');
            if (!userId) {
                setError('No se encontró la sesión del usuario');
                setLoading(false);
                return;
            }

            try {
                const response = await axios.get(`${apiUrl}get_client_location.php`, {
                    params: { userId }
                });
                
                if (Array.isArray(response.data) && response.data.length > 0) {
                    
                    const map = {};
                    const clientsWithCompositeValues = response.data.map(client => {
                        const ubicacion = client.idUbicacion !== undefined ? client.idUbicacion : `noUbic${Math.random()}`;
                        const clienteId = client.idCliente !== undefined ? client.idCliente : `noClient${Math.random()}`;
                        
                        const compositeValue = `${ubicacion}-${clienteId}`;
                        
                        map[compositeValue] = clienteId;
                        return {
                            ...client,
                            compositeValue
                        };
                    });
                    
                    setClientMap(map);
                    setClients(clientsWithCompositeValues);
                    
                    if (!selectedValue && clientsWithCompositeValues.length > 0) {
                        const firstCompositeValue = clientsWithCompositeValues[0].compositeValue;
                        setSelectedValue(firstCompositeValue);
                        onClientChange(clientsWithCompositeValues[0].idCliente);
                    }
                } else if (response.data.error) {
                    setError(response.data.error);
                } else {
                    setError('Formato de respuesta inválido');
                }
            } catch (error) {
                setError('Error al cargar los clientes: ' + (error.message || error));
                console.error('Error:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchClients();
    }, [apiUrl, onClientChange, selectedValue]);

    useEffect(() => {
        if (clients.length > 0 && !selectedValue) {
            const firstCompositeValue = clients[0].compositeValue;
            setSelectedValue(firstCompositeValue);
            onClientChange(clients[0].idCliente);
        }
    }, [clients, selectedValue, onClientChange]);

    const handleChange = (event) => {
        const newValue = event.target.value;
        setSelectedValue(newValue);
        
        if (newValue === 'otro') {
            setIsModalOpen(true);
        } else {
            const clientId = clientMap[newValue];
            onClientChange(clientId);
        }
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        if (clients.length > 0) {
            setSelectedValue(clients[0].compositeValue);
            onClientChange(clients[0].idCliente);
        }
    };

    if (loading) return <div>Cargando clientes...</div>;
    if (error) return <div className="error-message">{error}</div>;

    return (
        <div className='CC-2'>
            <label>{t('another_place')}</label>
            <select 
                onChange={handleChange} 
                value={selectedValue}
                className="client-select"
            >
                {clients.map(client => (
                    <option key={client.idUbicacion || Math.random()} value={client.compositeValue}>
                        {client.sNombreUbicacion}
                    </option>
                ))}
                <option value="otro">Otro</option>
            </select>
            {isModalOpen && (
                <Modal onClose={handleCloseModal}>
                    <h2>Añadir nuevo cliente</h2>
                </Modal>
            )}
        </div>
    );
};

export default DropDown;