import React, { useState, useEffect } from "react";
import axios from "axios";
import "../styles/register-staff.css";
import config from '../config';
import GoBack from '../components/GoBack';
import { useAuth } from '../AuthContext';
import "../styles/sweet-alert-styles.css";
import Swal from 'sweetalert2';

function RegistrationForm() {
  const [formData, setFormData] = useState({
    sUsuario: "",
    sContrasenya: "",
    dFAlta: "",
    TCategoria: "",
    sNombre_Completo: "",
    TContrato: "",
    sTlfmovil: "",
    sEmail: "",
    sNIF: "",
    sNSSSS: "",
  });
  
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const environment = process.env.NODE_ENV || 'development';
  const { getAuthToken } = useAuth();
  const userId = localStorage.getItem('userId');

  useEffect(() => {
    const fetchCategories = async () => {
      setIsLoading(true);
      try {
        const token = getAuthToken();
        const response = await axios.get(
          `${config[environment].API_BASE_URL}get_categories.php?userId=${userId}`,
          {
            headers: { 'Authorization': `Bearer ${token}` }
          }
        );

        if (response.data.success && Array.isArray(response.data.data)) {
          setCategories(response.data.data.map(cat => ({
            idCategoria: cat.id,
            sNombre: cat.nombre
          })));
        } else if (response.data.error) {
          setError(response.data.error);
        } else {
          setError("Error: Formato de respuesta inválido al cargar categorías");
        }
      } catch (error) {
        console.error("Error al cargar categorías:", error);
        setError(error.response?.data?.message || "Error al cargar las categorías. Por favor, intente de nuevo.");
      } finally {
        setIsLoading(false);
      }
    };

    if (userId) {
      fetchCategories();
    }
  }, [environment, userId, getAuthToken]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'dFAlta') {
      const dateValue = value ? new Date(value) : null;
      if (dateValue) {
        const day = String(dateValue.getDate()).padStart(2, '0');
        const month = String(dateValue.getMonth() + 1).padStart(2, '0');
        const year = dateValue.getFullYear();
        setFormData(prev => ({ ...prev, [name]: `${day}-${month}-${year}` }));
      }
    } else {
      setFormData(prev => ({ ...prev, [name]: value }));
    }
  };

  const resetForm = () => {
    setFormData({
      sUsuario: "",
      sContrasenya: "",
      dFAlta: "",
      TCategoria: "",
      sNombre_Completo: "",
      TContrato: "",
      sTlfmovil: "",
      sEmail: "",
      sNIF: "",
      sNSSSS: "",
    });
    setMessage("");
    setError("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const token = getAuthToken();
      const response = await axios.post(
        `${config[environment].PHP_REGISTER_URL}`,
        { ...formData, userId },
        {
          headers: { 
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.data.message) {
        await Swal.fire({
          title: '¡Éxito!',
          text: 'El empleado ha sido registrado correctamente',
          icon: 'success',
          confirmButtonText: 'Aceptar',
          confirmButtonColor: '#4CAF50'
        });
        resetForm();
      } else {
        throw new Error("Respuesta del servidor inválida");
      }
    } catch (error) {
      const errorMsg = error.response?.data?.message || 
                      error.message || 
                      "Error al procesar la solicitud";
      
      await Swal.fire({
        title: 'Error',
        text: errorMsg,
        icon: 'error',
        confirmButtonText: 'Entendido',
        confirmButtonColor: '#f44336'
      });
      console.error("Error detallado:", error);
    }
  };

  if (isLoading) {
    return <div className="RegForm"><p>Cargando categorías...</p></div>;
  }

  return (
    <div className="RegForm">
      <h1 className="RegTit">Registrar un nuevo empleado</h1>
      <form onSubmit={handleSubmit} className="RegFor">
        <div className="Reg-2">
          <div className="Reg-1">
            <label className="RegLab">Nombre de usuario *</label>
            <input
              type="text"
              name="sUsuario"
              value={formData.sUsuario}
              onChange={handleChange}
              placeholder="Ingresar nombre de usuario"
              required
              className="RegInp"
            />
            <label className="RegLab">Categoría *</label>
            <select
              name="TCategoria"
              value={formData.TCategoria}
              onChange={handleChange}
              required
              className="RegInp"
            >
              <option value="" disabled>
                Selecciona una categoría
              </option>
              {categories.map((category) => (
                <option key={category.idCategoria} value={category.idCategoria}>
                  {category.sNombre}
                </option>
              ))}
            </select>
            <label className="RegLab">Fecha de alta *</label>
            <input
              type="date"
              name="dFAlta"
              value={formData.dFAlta ? formData.dFAlta.split('-').reverse().join('-') : ''}
              onChange={handleChange}
              required
              className="RegInp"
            />
            <label className="RegLab">Nombre Completo *</label>
            <input
              type="text"
              name="sNombre_Completo"
              value={formData.sNombre_Completo}
              onChange={handleChange}
              placeholder="Ingresar Nombre Completo"
              required
              className="RegInp"
            />
          </div>
          <div className="Reg-1">
          <label className="RegLab">Contraseña *</label>
            <input
              type="password"
              name="sContrasenya"
              value={formData.sContrasenya}
              onChange={handleChange}
              placeholder="Ingresar contraseña"
              required
              className="RegInp"
            />
            <label className="RegLab">Tipo de contrato *</label>
            <select
              name="TContrato"
              value={formData.TContrato}
              onChange={handleChange}
              required
              className="RegInp"
            >
              <option value="" disabled>
                Selecciona un tipo de contrato
              </option>
              <option value="1">Indefinido</option>
              <option value="2">Temporal</option>
            </select>
            <label className="RegLab">Teléfono móvil</label>
            <input
              type="tel"
              name="sTlfmovil"
              value={formData.sTlfmovil}
              onChange={handleChange}
              placeholder="Ingresar Teléfono móvil"
              className="RegInp"
            />
            <label className="RegLab">Email</label>
            <input
              type="email"
              name="sEmail"
              value={formData.sEmail}
              onChange={handleChange}
              placeholder="Ingresar Email"
              className="RegInp"
            />
          </div>
        </div>
        <div className="Reg-1">
          <label className="RegLab">NIF</label>
          <input
            type="text"
            name="sNIF"
            value={formData.sNIF}
            onChange={handleChange}
            placeholder="Ingresar NIF"
            className="RegInp"
          />
          <label className="RegLab">Número de Seguridad Social *</label>
          <input
            type="text"
            name="sNSSSS"
            value={formData.sNSSSS}
            onChange={handleChange}
            placeholder="Ingresar Número de Seguridad Social"
            required
            className="RegInp"
          />
        </div>
        <button type="submit" className="RegBut">
          Registrar
        </button>
        <div className="SuErMessage">
          {message && <p className="success-message">{message}</p>}
          {error && <p className="error-message">{error}</p>}
        </div>
      </form>
      <GoBack />
    </div>
  );
}

export default RegistrationForm;