import React, { useState } from 'react';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import axios from 'axios';
import config from '../config';
import { formatDate, formatTime, validateWorkLog } from './dateTimeUtils';
import '../styles/pdfgenerator.css';

// const hexToRgb = (hex) => {
//     try {
//         const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
//         return result ? [
//             parseInt(result[1], 16),
//             parseInt(result[2], 16),
//             parseInt(result[3], 16)
//         ] : [0, 0, 0];
//     } catch (error) {
//         console.error('Error en hexToRgb:', error);
//         return [0, 0, 0];
//     }
// };

const UserPDFGenerator = ({
    selectedMarcajes,
    currentMarcajes,
    totalDuration
}) => {
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [showPreview, setShowPreview] = useState(false);
    const [pdfUrl, setPdfUrl] = useState(null);

    const generatePDFDocument = async () => {
        let doc = null;
        try {
            if (!selectedMarcajes || !currentMarcajes) {
                throw new Error('Datos de marcajes no válidos');
            }

            console.log('Datos de entrada:', {
                selectedMarcajes: Object.keys(selectedMarcajes).length,
                currentMarcajes: currentMarcajes.length,
                totalDuration
            });

            const empresaId = localStorage.getItem('empresaId');
            const userId = localStorage.getItem('userId');
            
            if (!empresaId || !userId) {
                throw new Error('No se encontró el ID de la empresa o del usuario');
            }

            const environment = process.env.NODE_ENV || 'development';
            const apiEndpoint = `${config[environment].API_BASE_URL}get_pdf_config.php`;
            
            console.log('Solicitando configuración a:', apiEndpoint);
            
            const response = await axios.get(apiEndpoint, {
                params: { empresaId }
            });

            if (!response.data || !response.data.success) {
                console.error('Respuesta del servidor:', response.data);
                throw new Error(response.data?.message || 'Error al obtener la configuración del PDF');
            }

            const pdfConfig = response.data.config;
            console.log('Configuración PDF recibida:', pdfConfig ? 'Sí' : 'No');

            const selectedRows = currentMarcajes
                .filter(marcaje => selectedMarcajes[marcaje.idMarcaje])
                .map(validateWorkLog);

            if (selectedRows.length === 0) {
                throw new Error("Por favor, seleccione al menos un marcaje para generar el PDF");
            }

            console.log('Filas seleccionadas:', selectedRows.length);

            doc = new jsPDF({
                orientation: 'portrait',
                unit: 'mm',
                format: 'a4'
            });

            doc.setFontSize(16);
            doc.text('Reporte de Marcajes', 20, 20);

            const userInfo = selectedRows[0]?.sNombre_Completo || 'Usuario';
            doc.setFontSize(12);
            doc.text(`Operario: ${userInfo}`, 20, 30);

            const tableHeaders = [['Fecha', 'Cliente', 'Hora Inicio', 'Hora Fin', 'Duración']];
            const tableData = selectedRows.map(marcaje => [
                formatDate(marcaje.dFecha),
                marcaje.sCliente,
                formatTime(marcaje.HoraIni),
                formatTime(marcaje.HoraFin),
                formatTime(marcaje.sDuracion)
            ]);

            doc.autoTable({
                head: tableHeaders,
                body: tableData,
                startY: 40,
                styles: {
                    fontSize: 10,
                    cellPadding: 2
                },
                columnStyles: {
                    0: { cellWidth: 30 },
                    1: { cellWidth: 50 },
                    2: { cellWidth: 30 },
                    3: { cellWidth: 30 },
                    4: { cellWidth: 30 }
                }
            });

            const finalY = doc.previousAutoTable.finalY || 40;
            doc.text(`Total: ${totalDuration}`, 20, finalY + 10);

            return doc;
        } catch (err) {
            const errorMessage = err.message || 'Error al generar el PDF';
            console.error('Error detallado en generatePDFDocument:', {
                message: errorMessage,
                error: err,
                stack: err.stack,
                doc: doc ? 'Documento creado' : 'Documento no creado'
            });
            setError(errorMessage);
            return null;
        }
    };

    const handlePreview = async () => {
        try {
            console.log('Iniciando vista previa...');
            setLoading(true);
            setError('');
            
            const doc = await generatePDFDocument();
            if (!doc) {
                throw new Error('No se pudo generar el documento PDF');
            }

            const pdfBlob = doc.output('bloburl');
            setPdfUrl(pdfBlob);
            setShowPreview(true);
        } catch (err) {
            console.error('Error en vista previa:', err);
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    const handleDownload = async () => {
        try {
            console.log('Iniciando descarga...');
            setLoading(true);
            setError('');
            
            const doc = await generatePDFDocument();
            if (!doc) {
                throw new Error('No se pudo generar el documento PDF');
            }

            const fechaActual = new Date().toISOString().split('T')[0];
            doc.save(`Mis_Marcajes_${fechaActual}.pdf`);
        } catch (err) {
            console.error('Error en descarga:', err);
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    const handleClosePreview = () => {
        setShowPreview(false);
        if (pdfUrl) {
            URL.revokeObjectURL(pdfUrl);
            setPdfUrl(null);
        }
    };

    const selectedCount = Object.values(selectedMarcajes).filter(Boolean).length;

    return (
        <div className="pdf-generator-container">
            <div className="pdf-generator-buttons">
                <button
                    onClick={handlePreview}
                    disabled={loading || selectedCount === 0}
                    className={`pdf-generator-button preview ${loading || selectedCount === 0 ? 'disabled' : ''}`}
                >
                    Vista Previa ({selectedCount} seleccionados)
                </button>
                <button
                    onClick={handleDownload}
                    disabled={loading || selectedCount === 0}
                    className={`pdf-generator-button download ${loading || selectedCount === 0 ? 'disabled' : ''}`}
                >
                    Descargar PDF
                </button>
            </div>

            {error && (
                <div className="pdf-error-message">
                    <span className="pdf-error-icon">!</span>
                    <p>{error}</p>
                </div>
            )}

            {showPreview && (
                <div className="pdf-preview-modal">
                    <div className="pdf-preview-content">
                        <div className="pdf-preview-header">
                            <h2>Vista Previa del PDF</h2>
                            <button onClick={handleClosePreview} className="pdf-preview-close">×</button>
                        </div>
                        <iframe src={pdfUrl} title="Vista previa del PDF" />
                        <div className="pdf-preview-footer">
                            <button onClick={handleDownload} className="pdf-generator-button download">
                                Descargar PDF
                            </button>
                            <button onClick={handleClosePreview} className="pdf-generator-button cancel">
                                Cerrar
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default UserPDFGenerator;